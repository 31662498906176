import DisclaimerService from '@core/services/disclaimer.service';
import React from 'react';
import { useMatch } from 'react-location';

interface DisclaimerGuardProps {
  children?: React.ReactNode;
}

export default function DisclaimerGuard({ children }: DisclaimerGuardProps): React.ReactElement | null {
  const disclaimer = DisclaimerService.getDisclaimer();
  const { search } = useMatch();

  if (disclaimer === false) {
    const url = new URL('/disclaimer', window.location.origin);
    if (window.location.pathname && window.location.pathname !== '/') {
      url.searchParams.set('redirectUrl', window.location.pathname);
    }
    if (search && Object.keys(search).length > 0) {
      Object.keys(search).forEach((key) => {
        url.searchParams.set(key, search[key] as string);
      });
    }
    window.location.href = url.href;
    return null;
  }
  return <>{children}</>;
}

import { useProfileDispatch } from '@context/Profile.context';
import { Profile } from '@model/profile';
import React, { useEffect } from 'react';
import { useMatch } from 'react-location';

const InitialData: React.FC = ({ children }) => {
  const {
    data: { profile },
  } = useMatch();
  const profileDispatch = useProfileDispatch();
  useEffect(() => {
    if (profile) {
      profileDispatch({ type: 'SET', payload: profile as Profile });
    }
  }, [profile, profileDispatch]);

  return <>{children}</>;
};

export default InitialData;

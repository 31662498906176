import axios from '@core/axios';
import { DiscountConditionInfoResponse, DiscountConditionSummaryResponse } from '@model/response/discount-condition-summary.response';
import { from, map, Observable } from 'rxjs';

export class DiscountConditionSummaryService {
  public static getDiscountConditionInfo(asOfYear: number): Observable<DiscountConditionInfoResponse> {
    const url = `/discount/info?asOfYear=${asOfYear}`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static getDiscountConditionSummary(asOfYear: number): Observable<DiscountConditionSummaryResponse> {
    const url = `/discount/summary?asOfYear=${asOfYear}`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }
}

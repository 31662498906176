import useFieldTranslate from '@helpers/hooks/useFieldTranslate';
import useGetCompany from '@helpers/hooks/useGetCompany';
import { Card, Spin } from '@mis/sushi-tailwind-react';
import React from 'react';
import DropdownYear from './DropdownYear';

const CompanyInformation = (): React.ReactElement => {
  const [data, isLoading] = useGetCompany();
  const fieldSector = useFieldTranslate('sector');
  const fieldCompany = useFieldTranslate('company');
  const fieldIndustry = useFieldTranslate('industry');

  if (isLoading) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <Card title={'ข้อมูลทั่วไปของบริษัท'} bordered={true} rounded={'rounded-lg'} loading={data == null}>
      {data && (
        <div className="flex flex-col gap-5 py-1.5 px-4 md:flex-row md:justify-between">
          <div className="flex flex-col grow shrink gap-1 justify-between md:flex-col md:gap-2">
            <div className="flex basis-1/2 flex-col gap-1 items-start md:flex-row md:gap-3">
              <span className="text-black md:max-w-[66%]">
                ชื่อบริษัท&nbsp;:&nbsp;
                <span className="font-bold" data-testid="company">
                  {data[fieldCompany]}
                </span>
              </span>
              <span className="text-black md:max-w-[34%]">
                ตัวย่อหลักทรัพย์&nbsp;:&nbsp;
                <span className="font-bold" data-testid="company-code">
                  {data.companyCode}
                </span>
              </span>
            </div>
            <div className="flex basis-1/2 flex-col gap-1 items-start md:flex-row md:gap-3 md:items-start">
              <div className="flex flex-col gap-1 md:flex-row md:gap-3">
                <span className="text-black">
                  Market:&nbsp;
                  <span className="font-bold text-black" data-testid="market">
                    {data.marketName}
                  </span>
                </span>
                <span className="text-black">
                  กลุ่มอุตสาหกรรม:&nbsp;
                  <span className="font-bold text-black" data-testid="industry">
                    {data[fieldIndustry]}
                  </span>
                </span>
              </div>
              <span className="text-black">
                หมวดธุรกิจ:&nbsp;
                <span className="font-bold text-black" data-testid="sector">
                  {data[fieldSector]}
                </span>
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2 md:flex-row md:items-center">
            <span className="w-max">ปีข้อมูล</span>
            <DropdownYear />
          </div>
        </div>
      )}
    </Card>
  );
};

export default CompanyInformation;

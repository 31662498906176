import { useProfileState } from '@context/Profile.context';
import { env } from '@core/env';
import { TokenService } from '@core/services/token.service';
import { UserRole } from '@model/enum/user-role.enum';
import { useMemo } from 'react';
import useTrueFalse from './useTrueFalse';

type OnToggle = () => void;
type OnClickExternalLink = () => void;

type ReturnType = [boolean, OnToggle, OnClickExternalLink];

export default function useLinkToLDP(): ReturnType {
  const [visible, onVisibleTrigger, setVisible] = useTrueFalse();
  const { profile } = useProfileState();

  const userRoles = useMemo(() => {
    if (!profile) {
      return [];
    }
    return profile?.roles.map((r) => r.roleName);
  }, [profile]);

  const onClickExternalLink = () => {
    if (!userRoles.includes(UserRole.ONE_REPORT_CREATOR) && !userRoles.includes(UserRole.ONE_REPORT_APPROVER)) {
      setVisible(true);
      return;
    }

    const url = new URL('/auth', env.ldpURL);
    const token = TokenService.getToken();
    if (token) {
      url.searchParams.set('JWT', token);
    }
    const refresh = TokenService.getRefreshToken();
    if (refresh) {
      url.searchParams.set('REFRESH', refresh);
    }
    window.open(url.href, '_black');
  };

  return [visible, onVisibleTrigger, onClickExternalLink];
}

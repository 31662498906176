import React from 'react';
import { FilterRoutesFn, Outlet, ReactLocation, Router } from 'react-location';
import { rankRoutes } from 'react-location-rank-routes';
import routers from 'router.config';
import './App.css';
import { SushiTailwindThemeProvider } from '@mis/sushi-tailwind-react';
import { ProfileProvider } from '@context/Profile.context';
import TheBull from '@components/the-bull/TheBull';
import { ProjectStateProvider } from '@context/ProjectState.context';
import { LayoutProvider } from '@context/Layout.context';
import BackToTopPage from '@components/back-to-top-page/BackToTopPage';

const location = new ReactLocation();

function App(): React.ReactElement {
  return (
    <SushiTailwindThemeProvider>
      <ProjectStateProvider>
        <LayoutProvider collapsed={false}>
          <ProfileProvider profile={null}>
            <Router location={location} filterRoutes={rankRoutes as FilterRoutesFn} routes={routers}>
              <Outlet />
              <TheBull />
              <BackToTopPage />
            </Router>
          </ProfileProvider>
        </LayoutProvider>
      </ProjectStateProvider>
    </SushiTailwindThemeProvider>
  );
}

export default App;

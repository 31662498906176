import React, { useEffect, useState } from 'react';
import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token.service';
import { UUIDService } from '@core/services/uuid.service';
import { Card, Icon } from '@mis/sushi-tailwind-react';
import { env } from '@core/env';
import { useTranslation } from 'react-i18next';

interface TokenState {
  jwt: string;
  refresh: string;
}

export default function AnotherUserLogin(): React.ReactElement | null {
  const { t } = useTranslation(['label']);
  const query = new URLSearchParams(window.location.search);
  const [token, setToken] = useState<TokenState>();

  useEffect(() => {
    const jwt = query.get('JWT') || query.get('jwt') || null;
    const refresh = query.get('REFRESH') || query.get('refresh') || null;
    if (jwt && refresh) {
      setToken({ jwt, refresh });
    } else {
      goToSetLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSetLink = () => {
    window.location.href = env.setLinkURL;
  };

  const onReject = () => {
    AuthService.clearCurrentUser().subscribe({
      next: () => {
        if (token) {
          TokenService.setToken(token.jwt);
          TokenService.setRefreshToken(token.refresh);
          UUIDService.getUUID();
          const ldpLanding = sessionStorage.getItem('redirect-url');
          if (ldpLanding) {
            sessionStorage.removeItem('redirect-url');
            window.location.href = ldpLanding;
          } else {
            window.location.href = '/';
          }
        } else {
          goToSetLink();
        }
      },
    });
  };

  const onCancel = () => {
    TokenService.clearToken();
    TokenService.clearRefreshToken();
    UUIDService.clearUUID();
    window.location.href = env.setLinkURL;
  };

  if (!token) {
    return null;
  }

  return (
    <div className="flex flex-col gap-5">
      <Card>
        <div className="flex flex-col gap-7 text-center">
          <Icon name="assignment_late" size="text-5xl" color="text-orange-500" />
          <div className="font-extrabold">{t('label:another_user_login.another_login')}</div>
          <div className="flex flex-col gap-3 text-center">
            <div className="text-[#00A7CC] cursor-pointer" onClick={onReject} data-testid={'button-reject'}>
              {t('label:another_user_login.reject_user')}
            </div>
            <div className="text-[#00A7CC] cursor-pointer" onClick={onCancel} data-testid={'button-cancel'}>
              {t('label:another_user_login.cancel_login')}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

import { useLayoutDispatch } from '@context/Layout.context';
import { Icon, Notification as notification, NotificationProps as SushiNotificationProps } from '@mis/sushi-tailwind-react';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type IconType = 'SAVE' | 'ERROR';
interface NotificationProps extends Pick<SushiNotificationProps, 'key' | 'message' | 'placement'> {
  iconType?: IconType;
  description?: React.ReactNode;
}

type SuccessReturnType = (args: NotificationProps) => void;
type ErrorReturnType = (args: NotificationProps) => void;
type ArrayReturnType = [SuccessReturnType, ErrorReturnType];
type ObjectReturnType = { success: SuccessReturnType; error: ErrorReturnType };

type ReturnType = ArrayReturnType & ObjectReturnType;

export const useNotification = (): ReturnType => {
  const dispatch = useLayoutDispatch();
  const { i18n } = useTranslation();

  const format = useMemo(() => {
    if (i18n.language === 'th') {
      return `DD MMM BBBB HH:mm`;
    }
    return `DD MMM YYYY HH:mm`;
  }, [i18n.language]);

  const showNotificationError = (args: NotificationProps): void => {
    notification.error({
      message: args.message,
      description: args.description,
      placement: args.placement || 'bottomRight',
      bottom: 43,
      right: 20,
      key: args.key || 'save_failed',
      onClose: () => {
        dispatch({ type: 'NOTIFICATION', payload: false });
      },
    });
    dispatch({ type: 'NOTIFICATION', payload: true });
  };

  const showNotificationSaveSuccess = (args: NotificationProps): void => {
    const date = dayjs().format(format);
    const description = args.description || (
      <div>
        <div className="text-[8px] text-gray-400">{date}</div>
      </div>
    );
    const icon = args.iconType || <Icon name="save" size="text-[30px]" className="text-primary-900" />;
    notification.success({
      message: args.message,
      description,
      placement: args.placement || 'bottomRight',
      key: args.key || 'save_success',
      bottom: 43,
      right: 20,
      icon,
      onClose: () => {
        dispatch({ type: 'NOTIFICATION', payload: false });
      },
    });
    dispatch({ type: 'NOTIFICATION', payload: true });
  };

  const result = [showNotificationSaveSuccess, showNotificationError];
  (result as unknown as ObjectReturnType).success = showNotificationSaveSuccess;
  (result as unknown as ObjectReturnType).error = showNotificationError;

  return result as ReturnType;
};

import { BullIsComingEnvType } from '@mis/the-bull-is-coming';
interface ENV {
  apiURL: string;
  setLinkURL: string;
  maxRequest: number;
  startYear: number;
  nodeEnv: string;
  websocketLog: boolean;
  ldpURL: string;
  fiscalEnd: string[];
  zwiz: {
    env: BullIsComingEnvType;
    clientSecret: string;
  };
  chatbot: {
    url: string;
    agentID: string;
    dlKey: string;
  };
}

const pEnv = process.env as Record<string, string>;

export const env: ENV = {
  apiURL: pEnv.REACT_APP_API_URL,
  setLinkURL: pEnv.REACT_APP_SETLINK_URL,
  maxRequest: parseInt(pEnv.REACT_APP_MAX_REQUEST, 10) || 1,
  startYear: pEnv.REACT_APP_START_YEAR ? parseInt(pEnv.REACT_APP_START_YEAR, 10) : 2022,
  nodeEnv: pEnv.REACT_APP_FRONT_ENV,
  websocketLog: pEnv.REACT_APP_WEB_SOCKET_LOG === 'true',
  ldpURL: pEnv.REACT_APP_LDP_URL,
  fiscalEnd: pEnv.REACT_APP_FISCAL_END.split(','),
  zwiz: {
    env: (process.env as Record<string, string>).REACT_APP_ZWIZ_ENV as BullIsComingEnvType,
    clientSecret: (process.env as Record<string, string>).REACT_APP_ZWIZ_CLIENT_SECRET,
  },
  chatbot: {
    url: (process.env as Record<string, string>).REACT_APP_CHATBOT_URL,
    agentID: (process.env as Record<string, string>).REACT_APP_CHATBOT_AGENT_ID,
    dlKey: (process.env as Record<string, string>).REACT_APP_CHATBOT_DL_KEY,
  },
};

import { Observable, from } from 'rxjs';

import { MaxFileSize } from '@model/max-file-size';
import axios from '@core/axios';
import { map } from 'rxjs/operators';
import { InfoCategory } from '@model/enum/info-category';
import { InfoTypeConfig } from '@model/info-type-config';
import ConfigCurrentTimeResponse from '@model/response/config-current-time.response';
import { DirectorTraining } from '@model/result-of-corporate-governance';
import RemarkConfigResponse from '@model/response/remark-config.response';

export interface InfoTypeConfigParam {
  infoCategory: InfoCategory;
  params?: { active?: boolean; query?: string };
  cache?: boolean;
}

export class ConfigService {
  private static fileSize$: { [key in string]: Observable<MaxFileSize> } = {};
  private static infoTypeConfig$: { [key in InfoCategory]?: Observable<InfoTypeConfig[]> } = {};
  private static remarkConfig$: Observable<RemarkConfigResponse> | null = null;

  public static getMaxFileSize(params: { type: string }): Observable<MaxFileSize> {
    const url = `/config/maxFileSize`;
    if (!this.fileSize$[params.type]) {
      this.fileSize$[params.type] = from(axios.get(url, { params })).pipe(map((d) => d.data));
    }
    return this.fileSize$[params.type];
  }

  public static infoTypeConfigs({ infoCategory, params = {}, cache = true }: InfoTypeConfigParam): Observable<InfoTypeConfig[]> {
    if (!this.infoTypeConfig$[infoCategory]) {
      const url = `/config/infoTypeConfig/${infoCategory}`;
      const from$ = from(axios.get<InfoTypeConfig[]>(url, { params })).pipe(map((d) => d.data));
      if (!cache) {
        return from$;
      }
      this.infoTypeConfig$[infoCategory] = from$;
    }
    return this.infoTypeConfig$[infoCategory] as Observable<InfoTypeConfig[]>;
  }

  public static getCurrentDateTime(): Observable<ConfigCurrentTimeResponse> {
    const url = `/config/time`;
    return from(axios.get(url)).pipe(map((d) => d.data));
  }

  public static getDirectorTraining(): Observable<DirectorTraining[]> {
    return from(axios.get('/config/directorTraining')).pipe(map((d) => d.data));
  }

  public static getRemarkConfig(): Observable<RemarkConfigResponse> {
    const url = '/config/remark';
    if (this.remarkConfig$ === null) {
      this.remarkConfig$ = from(axios.get(url)).pipe(map((d) => d.data));
    }
    return this.remarkConfig$;
  }
}

import { Spin } from '@mis/sushi-tailwind-react';
import React from 'react';

interface CardLoadingProps {
  loading?: boolean;
}

const CardLoading: React.FC<CardLoadingProps> = ({ loading, children }): React.ReactElement | null => {
  return (
    <div className="relative">
      {loading && (
        <div className="flex absolute top-0 justify-center items-center w-full h-full">
          <Spin />
        </div>
      )}
      {children}
    </div>
  );
};

export default CardLoading;

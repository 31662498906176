import { AuthService } from '@core/services/auth.service';
import { TokenService } from '@core/services/token.service';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-location';

export interface AuthWrapperChildrenProps {
  isSetData?: boolean | null;
  redirectUrl?: string | null;
}

interface AuthWrapperProps {
  children: (a: AuthWrapperChildrenProps) => React.ReactNode;
}

export default function AuthWrapper({ children }: AuthWrapperProps): React.ReactElement {
  const location = useLocation();
  const [isSetData, setIsSetData] = useState<boolean | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    const search = location.current.search;
    const jwt = search['jwt'] || search['JWT'] || null;
    const refresh = search['refresh'] || search['REFRESH'] || null;
    const url = search['redirectUrl'] || search['redirectUrl'] || null;
    setRedirectUrl(url as string);
    console.log('search: ', search);
    // console.log('jwt: ', jwt);
    // console.log('refresh: ', refresh);
    if (jwt) {
      if (TokenService.getToken()) {
        TokenService.clearToken();
      }
      if (refresh) {
        TokenService.setRefreshToken(refresh as string);
      } else {
        TokenService.clearRefreshToken();
      }
      TokenService.setToken(jwt as string);
      // UUIDService.getUUID();
      AuthService.authentication(jwt as string).subscribe({
        next: () => {
          setIsSetData(true);
        },
      });
      // window.location.href = '/';
    } else {
      // window.location.href = env.setLinkURL;
      setIsSetData(false);
    }
  }, [location]);
  return <>{children({ isSetData, redirectUrl })}</>;
}

export const withAuthWrapper = (WrappedComponent: React.ComponentType<AuthWrapperChildrenProps>) => {
  return function layout(props: AuthWrapperChildrenProps): JSX.Element {
    return (
      <AuthWrapper>
        {({ isSetData, redirectUrl }) => {
          return <WrappedComponent {...props} isSetData={isSetData} redirectUrl={redirectUrl} />;
        }}
      </AuthWrapper>
    );
  };
};

// export const withAuthWrapper = (WrappedComponent: React.ComponentType<AuthWrapperChildrenProps>) => {
// console.log('xx');
// return function layout(props: AuthWrapperChildrenProps): JSX.Element {
//   return (
//     <AuthWrapper>
//       <WrappedComponent {...props} />
//     </AuthWrapper>
//   );
// };
// return (
//   <AuthWrapper>
//     {(props) => {
//       console.log('ccc');
//       return <Component {...props} />;
//     }}
//   </AuthWrapper>
// );
// };

import { AuthService } from '@core/services/auth.service';
import { ReportService } from '@core/services/report.service';
import { TokenService } from '@core/services/token.service';
import { Profile } from '@model/profile';
import { ReportResponse } from '@model/response/report.response';
import { AxiosResponse } from 'axios';
import { catchError, firstValueFrom, forkJoin, of, switchMap, throwError } from 'rxjs';

export interface DefaultData {
  profile: Profile;
  report: ReportResponse | null;
}

export default async function getDefaultData(reportId?: string): Promise<Partial<DefaultData>> {
  const jwt = TokenService.getToken();

  const handleError = (e: AxiosResponse) => {
    if (e.status === 404) window.location.href = '/';
    return throwError(e);
  };

  const data$: AnyValue = reportId
    ? ReportService.getReport(reportId).pipe(
        switchMap((report) => forkJoin({ report: of(report), profile: AuthService.authentication(jwt as string) })),
        catchError(handleError)
      )
    : forkJoin({ report: of(null), profile: AuthService.authentication(jwt as string) }).pipe(catchError(handleError));

  return await firstValueFrom(data$);
}

import { Dispatch, SetStateAction, useState } from 'react';

type OnToggle = () => void;
type OnTrue = () => void;
type OnFalse = () => void;
type SetValue = Dispatch<SetStateAction<boolean>>;

interface ObjectReturnType {
  value: boolean;
  onToggle: OnToggle;
  onTrue: OnTrue;
  onFalse: OnFalse;
  setValue: SetValue;
}
type ArrayReturnType = [boolean, OnToggle, SetValue, OnTrue, OnFalse];

type ReturnType = ArrayReturnType & ObjectReturnType;

export default function useTrueFalse(defaultValue = false): ReturnType {
  const [value, setValue] = useState<boolean>(defaultValue);
  const onToggle = () => {
    setValue((d) => !d);
  };
  const onTrue = () => {
    setValue(true);
  };
  const onFalse = () => {
    setValue(false);
  };

  const result = [value, onToggle, setValue, onTrue, onFalse];
  (result as unknown as ObjectReturnType).value = value;
  (result as unknown as ObjectReturnType).onToggle = onToggle;
  (result as unknown as ObjectReturnType).onTrue = onTrue;
  (result as unknown as ObjectReturnType).onFalse = onFalse;
  (result as unknown as ObjectReturnType).setValue = setValue;

  return result as ReturnType;
}

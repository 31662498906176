import { useProfileState } from '@context/Profile.context';
import { useReportState } from '@context/Report.context';
import { useReportStatusState } from '@context/ReportStatus.context';
import { Icon, Tag } from '@mis/sushi-tailwind-react';
import React, { useMemo } from 'react';

function UserCurrentReportNode(): React.ReactElement | null {
  const { currentUserReports } = useReportState();
  const { currentNode } = useReportStatusState();
  const { profile } = useProfileState();
  const currentUser = useMemo(() => {
    return currentUserReports.find((c) => c.nodeName === currentNode && c.userProfile !== null && profile?.id !== c.userProfile.id);
  }, [currentNode, currentUserReports, profile?.id]);

  if (currentUser && currentUser.userProfile) {
    return (
      <div>
        <Tag color="#fcb034" style={{ height: '30px', color: 'white' }}>
          <span className="flex flex-row gap-2 items-center">
            <Icon name="account_circle" color="text-tertiary" size="text-xl" type="outlined" />
            <span>
              {currentUser.userProfile.firstNameTh}&nbsp;{currentUser.userProfile.lastNameTh}
            </span>
          </span>
        </Tag>
      </div>
    );
  }
  return null;
}

export default React.memo(UserCurrentReportNode, () => true);

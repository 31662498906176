import { CompanyService } from '@core/services/company.service';
import { Company } from '@model/company';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import useTrueFalse from './useTrueFalse';

export default function useGetCompany(): [null | Company, boolean, AxiosResponse | null] {
  const [data, setData] = useState<null | Company>(null);
  const [isLoading, onTriggerLoading] = useTrueFalse(false);
  const [errorResponse, setErrorResponse] = useState<AxiosResponse | null>(null);

  useEffect(() => {
    onTriggerLoading();
    CompanyService.getCompany()
      .pipe(
        finalize(() => {
          onTriggerLoading();
        })
      )
      .subscribe({
        next: (response) => {
          setData(response);
        },
        error: (e: AxiosResponse) => {
          setErrorResponse(e);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [data, isLoading, errorResponse];
}

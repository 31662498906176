import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from '@assets/styles/global-styles';
import '@mis/sushi-tailwind-react/dist/sushi.css';
import 'dayjs/locale/en';
import 'dayjs/locale/th';

import * as buddhistEra from 'dayjs/plugin/buddhistEra';
import * as dayjs from 'dayjs';

dayjs.extend(buddhistEra as unknown as dayjs.PluginFunc<unknown>);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useMemo } from 'react';
import { dateToFormat } from '@helpers/utils/date-to-format';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-location';

interface TableDetailProps {
  link?: string | null;
  updateDate?: string | null;
  updateUser?: string | null;
  nodeName: string;
}

const TableDetail = ({ link, updateDate, updateUser, nodeName }: TableDetailProps): React.ReactElement => {
  const hasLink = link && link != null;
  const { i18n } = useTranslation();

  const format = useMemo(() => {
    dayjs.locale(i18n.language);
    const year = i18n.language === 'th' ? 'BBBB' : 'YYYY';
    return `D MMM ${year}`;
  }, [i18n.language]);
  return (
    <>
      <td className="text-center">
        {hasLink ? (
          <Link to={link} data-test={`${nodeName}-link`}>
            <span className="text-[#00A7CC] underline">เพิ่ม/แก้ไขข้อมูล</span>
          </Link>
        ) : (
          <span className="text-black/[0.35] underline">ยังไม่เปิดให้ใช้งาน</span>
        )}
      </td>
      <td>
        <span data-test={`${nodeName}-updated-date`}>{updateDate ? dateToFormat(new Date(updateDate), format) : '-'}</span>
      </td>
      <td>
        <span data-test={`${nodeName}-updated-user`}>{updateUser ? updateUser : '-'}</span>
      </td>
    </>
  );
};

export default TableDetail;

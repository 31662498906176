import { useProjectStateDispatch, useProjectStateState } from '@context/ProjectState.context';
import { env } from '@core/env';
import DisclaimerService from '@core/services/disclaimer.service';
import styled from '@emotion/styled';
import { Button, Card, Checkbox } from '@mis/sushi-tailwind-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, ReactLocation } from 'react-location';

const CustomLiStyle = styled.li`
  counter-increment: item;
  &::marker {
    content: '(' counter(item) ') ';
  }
`;
const reactLocation = new ReactLocation();

export default function DisclaimerPage(): React.ReactElement {
  const { t } = useTranslation(['description']);
  const { disclaimer } = useProjectStateState();
  const dispatch = useProjectStateDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const goToSETLink = () => {
    window.location.href = env.setLinkURL;
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const check = e.target.checked;
    setIsChecked(check);
  };

  const onConfirm = () => {
    if (!isChecked) {
      return;
    }
    DisclaimerService.setDisclaimer(isChecked);
    dispatch({ type: 'SET_DISCLAIMER', payload: true });
    const { redirectUrl, ...search } = reactLocation.current.search;
    const pathname = redirectUrl ? (redirectUrl as string) : '/';
    const url = new URL(pathname, window.location.origin);

    if (search && Object.keys(search).length > 0) {
      Object.keys(search).forEach((key) => {
        url.searchParams.set(key, search[key] as string);
      });
    }
    console.log(url.href, redirectUrl);
    window.location.href = url.href;
  };

  if (disclaimer === true) {
    return <Navigate to="/" />;
  }
  return (
    <div className="flex flex-col gap-5">
      <div className="text-2xl font-bold text-[#212121]">{t('description:disclaimer.title')}</div>
      <Card title="ข้อตกลงและเงื่อนไขการใช้งาน">
        <div className="flex flex-col gap-4">
          <div className="overflow-auto" style={{ height: 'calc(100vh - 350px)' }}>
            <ol className="list-decimal" style={{ paddingInlineStart: 15 }}>
              <li className="mt-2">{t('description:disclaimer.1')}</li>
              <li className="mt-2">{t('description:disclaimer.2')}</li>
              <li className="mt-2">{t('description:disclaimer.3')}</li>
              <li className="mt-2">{t('description:disclaimer.4')}</li>
              <li className="mt-2">{t('description:disclaimer.5')}</li>
              <ol className="list-decimal" style={{ paddingInlineStart: 20 }}>
                <CustomLiStyle className="mt-2">{t('description:disclaimer.5_1')}</CustomLiStyle>
                <CustomLiStyle className="mt-2">{t('description:disclaimer.5_2')}</CustomLiStyle>
              </ol>
              <li className="mt-2">{t('description:disclaimer.6')}</li>
              <li className="mt-2">{t('description:disclaimer.7')}</li>
              <li className="mt-2">{t('description:disclaimer.8')}</li>
              <li className="mt-2">{t('description:disclaimer.9')}</li>
            </ol>
            <div className="mt-2">{t('description:disclaimer.10')}</div>
          </div>
          <div className="flex justify-center">
            <label>
              <Checkbox checked={isChecked} onChange={onCheckboxChange} data-testid={`acceptCheckbox`}>
                ยอมรับข้อตกลงและเงื่อนไข
              </Checkbox>
            </label>
          </div>
          <div className="flex justify-between">
            <Button color="secondary" type="button" onClick={goToSETLink} data-testid="cancel">
              ยกเลิก
            </Button>
            <Button color="primary" type="button" onClick={onConfirm} disabled={!isChecked} data-testid="confirm">
              ยืนยัน
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

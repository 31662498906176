/* eslint-disable no-extra-boolean-cast */
import HeaderLayout from '@layouts/header/HeaderLayout';
import SiderLayout from '@layouts/sider/SiderLayout';
import React, { useEffect, useRef, useState } from 'react';
import { Layout, Sider, BreadcrumbRouteProps } from '@mis/sushi-tailwind-react';
import { useMatch, useMatches } from 'react-location';
import InitialData from '@components/initial-data/InitialData';
import { useLayoutDispatch, useLayoutState } from '@context/Layout.context';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { RouteMeta } from '@model/route-meta';
import { ReportProvider } from '@context/Report.context';
import styled from '@emotion/styled';
import { ReportResponse } from '@model/response/report.response';
import getYearString from '@helpers/utils/get-year-string';
import { Locale } from '@model/local.types';
import ReportContent from './ReportContent';

const SiderComponent = styled(Sider)`
  position: fixed;
`;

type ReportLayoutProps = {
  children?: React.ReactNode;
};

export const ReportLayout = ({ children }: ReportLayoutProps): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const hashRefCount = useRef(0);
  const spinRefCount = useRef(0);
  const [connected, setConnected] = useState(false);
  const {
    params: { reportId },
    data: { report },
    ...match
  } = useMatch();
  const dispatch = useLayoutDispatch();
  const { collapsed } = useLayoutState();
  const matches = useMatches();

  const routes = useMemo<BreadcrumbRouteProps[]>(() => {
    const filterMatches = _.flatten(matches.filter((m) => m.route.meta).map((m) => (m.route.meta as unknown as RouteMeta)?.breadcrumb()));
    return filterMatches.map((m: BreadcrumbRouteProps) => {
      if (m.label === 'year') {
        return {
          label: getYearString((report as ReportResponse).asOfYear, i18n.language as Locale),
          href: m.href,
        };
      }
      return {
        label: t(m.label),
        href: m.href,
      };
    });
  }, [matches, t, report, i18n.language]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    let internalFindSpin: NodeJS.Timer;
    let intervalFindBarLoading: NodeJS.Timer;
    let intervalFindBarLoadingDisappear: NodeJS.Timer;
    if (window.location.hash && connected) {
      const hash = window.location.hash;
      let windowHeight = window.innerHeight;

      internalFindSpin = setInterval(() => {
        const el = document.querySelector('#report-content');
        if (el?.querySelectorAll("[data-testid='sushi-spin']") !== undefined) {
          const len = el?.querySelectorAll("[data-testid='sushi-spin']").length;
          if (len > 1) {
            spinRefCount.current++;
          } else {
            spinRefCount.current = 0;
          }
        }
      }, 100);

      interval = setInterval(() => {
        const innerHeight = window.innerHeight;
        window.history.replaceState(null, document.title, ' ');
        const el = document.querySelector(hash);
        if (hash && document.querySelector(hash) && innerHeight === windowHeight) {
          scrollTo(hash, -150);
          if (spinRefCount.current === 0 || (el && el.getBoundingClientRect().top < 400)) {
            clearInterval(interval);
            clearInterval(internalFindSpin);
          }
        }
        if (hashRefCount.current >= 10) {
          clearInterval(interval);
          clearInterval(internalFindSpin);
        }
        windowHeight = innerHeight;
        hashRefCount.current++;
      }, 700);

      intervalFindBarLoading = setInterval(() => {
        if (document?.querySelectorAll("[data-testid='loadingRightButton']").length && hash) {
          intervalFindBarLoadingDisappear = setInterval(() => {
            if (document?.querySelectorAll("[data-testid='loadingRightButton']").length === 0 && hash) {
              scrollTo(hash, -150);
              clearInterval(intervalFindBarLoadingDisappear);
            }
          }, 700);
          clearInterval(intervalFindBarLoading);
        }
      }, 100);
    }

    return () => {
      clearInterval(interval);
      clearInterval(internalFindSpin);
      clearInterval(intervalFindBarLoading);
      clearInterval(intervalFindBarLoadingDisappear);
      hashRefCount.current = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const scrollTo = (selector: string, yOffset = 0) => {
    const el = document.querySelector(selector);
    if (el && el.getBoundingClientRect().top >= 400) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const onCollapse = (payload: boolean) => {
    dispatch({ type: 'COLLAPSED', payload });
  };

  return (
    <InitialData>
      <ReportProvider reportId={reportId}>
        <Layout className="min-h-screen">
          <HeaderLayout />
          <Layout className="mt-12">
            <SiderComponent
              collapsible
              className="left-0 h-full bg-white duration-300"
              breakpoint="md"
              width={'16.75rem'}
              collapsedWidth="0"
              collapsed={collapsed}
              onCollapse={onCollapse}
            >
              <SiderLayout collapsed={collapsed} onWsConnected={setConnected} />
            </SiderComponent>
            <ReportContent collapsed={collapsed} routes={routes} reportId={reportId} report={report as ReportResponse}>
              {children}
            </ReportContent>
          </Layout>
        </Layout>
      </ReportProvider>
    </InitialData>
  );
};

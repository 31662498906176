import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function useDateTimeFormat(): string {
  const { i18n } = useTranslation();
  return useMemo(() => {
    const year = i18n.language === 'th' ? 'BBBB' : 'YYYY';
    return `DD MMM ${year} เวลา HH:mm`;
  }, [i18n.language]);
}

import { v4 as uuidv4 } from 'uuid';

export class UUIDService {
  private static key = 'uuid';

  public static getUUID(): string {
    let uuid = localStorage.getItem(UUIDService.key);
    if (!uuid) {
      uuid = uuidv4();
      UUIDService.setUUID(uuid);
    }
    return uuid;
  }

  public static setUUID(uuid: string): void {
    localStorage.setItem(UUIDService.key, uuid);
  }

  public static clearUUID(): void {
    localStorage.removeItem(UUIDService.key);
  }
}

import { Statistic } from '@mis/sushi-tailwind-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DisclosureProgressProps {
  progress: number;
  label: string;
}

const DisclosureProgress = ({ label, progress }: DisclosureProgressProps): React.ReactElement => {
  const { t } = useTranslation(['translation']);
  const [value, setValue] = useState<number>(progress);
  useEffect(() => {
    changeNumber(value, progress, value < progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const changeNumber = (currentNumber: number, endNumber: number, isIncrease: boolean) => {
    if (isIncrease) {
      if (currentNumber <= endNumber) {
        setTimeout(() => {
          setValue(currentNumber);
          changeNumber(currentNumber + 1, endNumber, true);
        }, 10);
      }
    } else {
      if (currentNumber >= endNumber) {
        setTimeout(() => {
          setValue(currentNumber);
          changeNumber(currentNumber - 1, endNumber, false);
        }, 10);
      }
    }
  };

  return (
    <div className="flex justify-between">
      <span className="text-base dark:text-white" data-testid={`progress-label-${label}`}>
        {t(label)}
      </span>
      <span className="text-sm dark:text-white">
        <Statistic data-testid={`progress-value-${label}`} value={value || 0} suffix="%" precision={0} />
      </span>
    </div>
  );
};

export default DisclosureProgress;

import { ConfigService } from '@core/services/config.service';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { finalize } from 'rxjs';
import useTrueFalse from './useTrueFalse';
import { env } from '@core/env';

interface ServerCurrentDataTimeProps {
  currentDateTime: null | Date;
  defaultYear: number | null;
  firstYear: number;
}

export default function useServerCurrentDateTime(): [ServerCurrentDataTimeProps, boolean, AxiosResponse | null] {
  const [currentDateTime, setCurrentDateTime] = useState<ServerCurrentDataTimeProps>({
    currentDateTime: null,
    defaultYear: null,
    firstYear: env.startYear,
  });
  const [isLoading, onLoadingTrigger] = useTrueFalse(false);
  const [errMessage, setErrMessage] = useState<AxiosResponse | null>(null);
  useEffect(() => {
    onLoadingTrigger();
    ConfigService.getCurrentDateTime()
      .pipe(
        finalize(() => {
          onLoadingTrigger();
        })
      )
      .subscribe({
        next: ({ current, defaultYear, firstYear }) => {
          const date = new Date(current);
          setCurrentDateTime({ currentDateTime: date, defaultYear, firstYear: firstYear ?? env.startYear });
        },
        error: (e: AxiosResponse) => {
          setErrMessage(e);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [currentDateTime, isLoading, errMessage];
}

import { Observable, from } from 'rxjs';

import axios from '@core/axios';
import { map } from 'rxjs/operators';
import { Profile } from '@model/profile';

interface ClearCurrentUserResponse {
  id: number;
  email: string;
  firstNameTh: string;
  lastNameTh: string;
  username: string;
  currentIp: string | null;
}

export class AuthService {
  public static authentication(jwt: string): Observable<Profile> {
    const url = '/auth/profile';
    return from(
      axios.post<Profile>(url, null, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
    ).pipe(map((d) => d.data));
  }

  public static clearCurrentUser(): Observable<ClearCurrentUserResponse> {
    const url = '/auth/clear';
    return from(axios.post<ClearCurrentUserResponse>(url, null)).pipe(map((d) => d.data));
  }
}

import React from 'react';
import { useLayoutState } from '@context/Layout.context';
import { BackTop } from '@mis/sushi-tailwind-react';
import { useMemo } from 'react';

const BackTopTopPage = () => {
  const { notificationOpen } = useLayoutState();
  const backTopClassName = useMemo(() => {
    return notificationOpen ? 'fixed bottom-[110px] right-[20px]' : 'fixed bottom-14 right-[20px]';
  }, [notificationOpen]);

  return <BackTop data-testid="backtop" className={backTopClassName} visibilityHeight={150} />;
};

export default React.memo(BackTopTopPage);

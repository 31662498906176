export default class DisclaimerService {
  public static key = 'disclaimer';

  public static getDisclaimer(): boolean {
    return sessionStorage.getItem(this.key) === 'true';
  }

  public static setDisclaimer(value: boolean): void {
    sessionStorage.setItem(this.key, `${value}`);
  }
}

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    keySeparator: '.',
    lng: 'th',
    fallbackLng: 'th',
    preload: ['th'],
    ns: ['translation'],
    defaultNS: 'translation',
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
    debug: process.env.NODE_ENV !== 'production',
    react: {
      useSuspense: false,
    },
  });
export default i18n;

import $axios from 'axios';
import { finalize } from 'rxjs';
import { env } from './env';
import { TokenService } from './services/token.service';
import { UUIDService } from './services/uuid.service';
import React from 'react';
import * as ReactDOM from 'react-dom';
import AnotherUserLoginPopup from 'pages/another-user-login/AnotherUserLoginPopup';

let isRefreshing = false;
let failedQueue: { reject: (d: unknown) => void; resolve: (d: unknown) => void }[] = [];
const MAX_REQUESTS_COUNT = env.maxRequest;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const baseURL = env.apiURL;
const axios = $axios.create({ baseURL });

axios.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      if (config.headers && !config.headers['Authorization']) {
        const accessToken = sessionStorage.getItem('token');
        if (accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }
      }
      if (config.headers && !config.headers['uuid']) {
        const uuid = UUIDService.getUUID();
        config.headers = {
          ...config.headers,
          uuid: uuid,
        };
      }
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return response;
  },
  async (error) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    const originalRequest = error.config;
    console.log(error?.response?.data, originalRequest);

    if (error.response?.status === 403) {
      ReactDOM.render(<AnotherUserLoginPopup />, document.getElementById('root'));
    }

    if (error.response && error.response.status === 409 && error.response.data.code === 'user already in used') {
      const queryParams: { key: string; value: AnyValue }[] = [];
      const jwt = TokenService.getToken() || null;
      if (jwt) {
        queryParams.push({ key: 'jwt', value: jwt });
      }
      const refresh = TokenService.getRefreshToken() || null;
      if (refresh) {
        queryParams.push({ key: 'refresh', value: refresh });
      }

      if (window.location.pathname.includes('/ldp-landing') || window.location.search.includes('redirectUrl')) {
        sessionStorage.setItem('redirect-url', window.location.href);
      }

      let url = '/another-user-login';
      if (queryParams.length > 0) {
        url += '?' + queryParams.map((q) => `${q.key}=${q.value}`).join('&');
      }
      window.location.href = url;
      return Promise.reject(error.response);
    }
    if (error.response && error.response.status === 423) {
      window.location.reload();
      return Promise.reject(error.response);
    }

    if (error.response && error.response.status === 401 && originalRequest.url.includes('/auth/refreshToken')) {
      TokenService.clearToken();
      TokenService.clearRefreshToken();
      window.location.href = env.setLinkURL;
    }

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        TokenService.refreshToken()
          .pipe(
            finalize(() => {
              isRefreshing = false;
            })
          )
          .subscribe({
            next: ({ data }) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
              processQueue(null, data.token);
              resolve(axios(originalRequest));
            },
            error: (err) => {
              processQueue(err, null);
              reject(err);
            },
          });
      });
    }

    if (error.response && error.response.status === 500 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve) => {
        setTimeout(() => {
          const token = sessionStorage.getItem('token');
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          processQueue(null, token);
          isRefreshing = false;
          resolve(axios(originalRequest));
        }, 2000);
      });
    }
    return Promise.reject(error.response);
  }
);

export default axios;

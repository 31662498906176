import React, { useReducer } from 'react';

type Action =
  | { type: 'COLLAPSED'; payload: boolean }
  | { type: 'NOTIFICATION'; payload: boolean }
  | { type: 'SHOW_BACK_TO_TOP'; payload: boolean };
type Dispatch = (action: Action) => void;
type State = {
  collapsed: boolean;
  notificationOpen: boolean;
  showBackToTop: boolean;
};

const LayoutStateContext = React.createContext<State | undefined>(undefined);
const LayoutDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'COLLAPSED':
      return { ...state, collapsed: action.payload };
    case 'NOTIFICATION':
      return { ...state, notificationOpen: action.payload };
    case 'SHOW_BACK_TO_TOP': {
      if (state.showBackToTop === action.payload) {
        return state;
      }
      return { ...state, showBackToTop: action.payload };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface LayoutProviderProps {
  collapsed: boolean;
}
const LayoutProvider: React.FC<LayoutProviderProps> = ({ collapsed, children }) => {
  const [state, dispatch] = useReducer(reducer, { collapsed, notificationOpen: false, showBackToTop: false });

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

const useLayoutState = (): State => {
  const context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
};
const useLayoutDispatch = (): Dispatch => {
  const context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
};

export { LayoutProvider, useLayoutState, useLayoutDispatch };

import CardTooltip from '@components/card-tooltip/CardTooltip';
import React, { FormEvent, useMemo } from 'react';
import ComponentWithLabel from '@components/component-with-label/ComponentWithLabel';
import { RadioGroup } from '@components/radio-group/RadioGroup';
import { DownloadService } from '@core/services/download.service';
import { Button, Checkbox, Dropdown, DropdownOption } from '@mis/sushi-tailwind-react';
import { Locale } from '@model/local.types';
import FileSaver from 'file-saver';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import getYearString from '@helpers/utils/get-year-string';
import { preventFormSubmit } from '@helpers/utils/prevent-form-submit';
import { useYearOptions } from '@helpers/hooks/useYearOptions';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import { finalize } from 'rxjs';
import { useLandingState } from '../Landing.context';
import { useNotification } from '@helpers/hooks/useNotification';
import ExplanationLabel from '@components/explanation-label/ExplanationLabel';
import { ExportReportPart } from '@model/enum/export-report-part';
import useServerCurrentDateTime from '@helpers/hooks/useServerCurrentTime';

const types = [
  { value: 'PDF', label: 'PDF' },
  { value: 'XLS', label: 'Excel (.xls)' },
];
const Export = (): React.ReactElement => {
  const { t } = useTranslation(['title', 'label', 'button']);
  const { currentDateTime, defaultYear } = useLandingState();
  const [cDateTime] = useServerCurrentDateTime();
  const years = useYearOptions(cDateTime.firstYear, currentDateTime, defaultYear);
  const options = useMemo<DropdownOption[]>(() => {
    if (Array.isArray(years)) {
      return years.map((year) => ({ label: getYearString(year), value: year }));
    }
    return [];
  }, [years]);
  const [loading, onLoadingTrigger] = useTrueFalse(false);
  const notification = useNotification();

  const parts = [
    { value: ExportReportPart.ENVIRONMENT, label: `ข้อมูลด้าน${t('translation:reportNode.environment.title')}` },
    { value: ExportReportPart.SOCIAL, label: `ข้อมูลด้าน${t('translation:reportNode.social.title')}` },
    { value: ExportReportPart.GOVERNANCE, label: `ข้อมูลด้าน${t('translation:reportNode.governance.title')}` },
  ];

  const languages = [
    { label: t('label:export.thai'), value: 'th' },
    { label: t('label:export.english'), value: 'en' },
  ];

  const onSubmit = ({ type, year, part, language }: AnyValue) => {
    onLoadingTrigger();
    DownloadService.preview(year, language as Locale, part.join(','), type)
      .pipe(finalize(() => onLoadingTrigger()))
      .subscribe({
        next: (response) => {
          const { data, headers } = response;
          const fileName = headers['content-disposition'].split('filename=')[1];
          FileSaver.saveAs(new Blob([data]), fileName);
        },
        error: () => notification.error({ message: t('translation:alert.download_failed') }),
      });
  };

  const validate = (value: string | string[]) => {
    if (!value || value.length === 0) return 'required';
    return;
  };

  const onDownloadClick =
    (setFieldTouched: (field: string, isTouched?: boolean) => void, handleSubmit: (e?: FormEvent<HTMLFormElement>) => void) => () => {
      setTimeout(() => {
        setFieldTouched('year', true);
        setFieldTouched('part', true);
      }, 100);
      handleSubmit();
    };

  return (
    <CardTooltip title={t('export.title')}>
      <ExplanationLabel label={t('label:export.explanation')} iconPosition={'start'} />
      <Formik initialValues={{ type: 'PDF', language: 'th' }} onSubmit={onSubmit} validateOnChange={true}>
        {({ setFieldTouched, handleSubmit }) => (
          <Form onKeyPress={preventFormSubmit}>
            <div className="flex flex-col gap-3 mt-4">
              <ComponentWithLabel label={<div className="md:mt-[6px]">{t('label:export.year')}</div>} labelAlign="start">
                <ComponentWithLabel.Children>
                  <div className="w-44 h-12">
                    <Field name="year" validate={validate}>
                      {({ field, meta }: AnyValue) => (
                        <Dropdown
                          {...field}
                          placeholder={t('label:other.please_select_year')}
                          options={options}
                          onChange={(value) => field.onChange({ target: { name: 'year', value } })}
                          status={meta.error && meta.touched ? 'error' : undefined}
                          message="ยังไม่ได้เลือกปีข้อมูล"
                          data-testid="download-year-dropdown"
                        />
                      )}
                    </Field>
                  </div>
                </ComponentWithLabel.Children>
              </ComponentWithLabel>
              <ComponentWithLabel label={t('label:export.language')} labelAlign="start">
                <ComponentWithLabel.Children>
                  <RadioGroup items={languages} type="language" />
                </ComponentWithLabel.Children>
              </ComponentWithLabel>
              <ComponentWithLabel label={t('label:export.part')} labelAlign="start">
                <ComponentWithLabel.Children>
                  <div className="flex justify-between">
                    <div className="flex flex-col gap-[0.4rem]">
                      {parts.map(({ value, label }, index) => (
                        <Field key={value} name="part" validate={validate}>
                          {({ field, meta }: AnyValue) => (
                            <div className={index === parts.length - 1 ? 'h-8' : undefined}>
                              <Checkbox
                                {...field}
                                checked={(field.value || []).includes(value)}
                                value={value}
                                data-testid={`${value}-checkbox`}
                                status={meta.error && meta.touched ? 'error' : undefined}
                                message={index === parts.length - 1 && meta.error ? 'ยังไม่ได้เลือกชุดข้อมูล' : undefined}
                              >
                                {label}
                              </Checkbox>
                            </div>
                          )}
                        </Field>
                      ))}
                    </div>
                  </div>
                </ComponentWithLabel.Children>
              </ComponentWithLabel>
              <ComponentWithLabel label={t('label:export.type')} labelAlign="start" tooltip={t('label:export.type_tooltip')}>
                <ComponentWithLabel.Children>
                  <RadioGroup items={types} type="type" />
                </ComponentWithLabel.Children>
              </ComponentWithLabel>
              <ComponentWithLabel>
                <ComponentWithLabel.Children>
                  <div className="mt-2">
                    <Button
                      type="button"
                      onClick={onDownloadClick(setFieldTouched, handleSubmit)}
                      data-testid="download-button"
                      color="primary"
                      loading={loading}
                    >
                      {t('button:download')}
                    </Button>
                  </div>
                </ComponentWithLabel.Children>
              </ComponentWithLabel>
            </div>
          </Form>
        )}
      </Formik>
    </CardTooltip>
  );
};
export default Export;

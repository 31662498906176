import { CompatClient, Stomp, StompSubscription, messageCallbackType, StompHeaders } from '@stomp/stompjs';
import { useCallback, useMemo } from 'react';

import { env } from '@core/env';
import { TokenService } from '@core/services/token.service';
import SockJS from 'sockjs-client';

// declare class SockJS {
//   constructor(url: string);
// }

export interface UseWebsocketRet {
  client?: CompatClient;
  connect: (callback: (frame: unknown) => void) => void;
  disconnect: () => void;
  subscribe: (destination: string, callback: messageCallbackType) => StompSubscription | undefined;
  unsubscribe: (id: string) => void;
  getHeaders: () => StompHeaders;
}

const useWebsocket = (): UseWebsocketRet => {
  const getHeaders = useCallback(() => {
    return {
      Authorization: `Bearer ${TokenService.getToken()}`,
      'X-Authorization': `Bearer ${TokenService.getToken()}`,
    } as StompHeaders;
  }, []);

  const client = useMemo(() => {
    const stomp = Stomp.over(() => {
      return new SockJS(`${env.apiURL}/ws`);
    });
    stomp.reconnectDelay = 1500;
    stomp.logRawCommunication = false;
    stomp.debug = (str) => {
      if (env.websocketLog) {
        console.log(str);
      }
    };
    stomp.beforeConnect = async () => {
      const refreshToken = TokenService.getRefreshToken();
      const headers = getHeaders();
      if (refreshToken) {
        const token = await TokenService.refreshToken().toPromise();
        if (token?.data) {
          headers['Authorization'] = `Bearer ${token.data.token}`;
          headers['X-Authorization'] = `Bearer ${token.data.token}`;
        }
      }
      stomp.connectHeaders = headers;
    };
    return stomp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const subscribe = useCallback(
    (destination: string, callback: messageCallbackType) => {
      const headers = getHeaders();
      return client && client.subscribe && client.subscribe(destination, callback, headers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  const unsubscribe = useCallback(
    (id: string) => {
      const headers = getHeaders();
      return client && client.unsubscribe && client.unsubscribe(id, headers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  const connect = useCallback(
    (callback: (frame: unknown) => void) => {
      if (client.connected) {
        return callback(null);
      }
      const headers = getHeaders();
      return client && client.connect && client.connect(headers, callback);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  const disconnect = useCallback(() => {
    if (client.connected) {
      const headers = getHeaders();
      client.disconnect((a: unknown) => {
        console.log('disconnect', a);
      }, headers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return {
    client,
    connect,
    getHeaders,
    disconnect,
    subscribe,
    unsubscribe,
  };
};

export default useWebsocket;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';

interface SearchChangeProps {
  debounceMs: number;
}

const SearchChange = ({ debounceMs = 1000 }: SearchChangeProps): React.ReactElement => {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState<boolean | null>(null);
  const first = useRef(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    _.throttle(() => {
      return formik.submitForm().then(() => setIsSaved(true));
    }, debounceMs),
    [formik.submitForm, debounceMs]
  );

  useEffect(() => {
    if (first.current === false) {
      debouncedSubmit();
    }
    first.current = false;
  }, [debouncedSubmit, formik.values]);

  return <>{isSaved ? '' : ''}</>;
  // return <p className="text-center text-success">{!!formik.isSubmitting ? 'Saving...' : isSaved ? 'Your changes saved.' : null}</p>;
};

export default SearchChange;

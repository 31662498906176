import React from 'react';
import detectClassNames from '@helpers/utils/detectClassNames';

interface LabelProps {
  children?: React.ReactNode;
  className?: string;
  ['data-testid']?: string;
}

const ComponentLabel = React.forwardRef<HTMLDivElement, LabelProps>(({ children, className, ...props }, forwardRef) => {
  const classString = detectClassNames('md:w-2/5 md:text-right', className);
  return (
    <div ref={forwardRef} className={classString} data-testid={props['data-testid'] || 'component-with-label-label'}>
      {children}
    </div>
  );
});

ComponentLabel.displayName = 'ComponentLabel';

export default ComponentLabel;

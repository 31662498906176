import detectClassNames from '@helpers/utils/detectClassNames';
import React from 'react';

interface ComponentChildrenProps {
  children?: React.ReactNode;
  className?: string;
  ['data-testid']?: string;
}

const ComponentChildren = React.forwardRef<HTMLDivElement, ComponentChildrenProps>(({ children, className, ...props }, forwardRef) => {
  const classString = detectClassNames('w-full md:w-3/5', className);
  return (
    <div ref={forwardRef} className={classString} data-testid={props['data-testid'] || 'component-with-label-children'}>
      {children}
    </div>
  );
});

ComponentChildren.displayName = 'ComponentChildren';

export default ComponentChildren;

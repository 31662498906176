import React, { useEffect, useMemo, useState } from 'react';
import { Button, Column, Modal, Icon, Table, StatusIcon } from '@mis/sushi-tailwind-react';
import { useTranslation } from 'react-i18next';
import { DiscountConditionSummaryService } from '@core/services/discount-condition-summary.service';
import { finalize } from 'rxjs';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import dayjs from 'dayjs';
import { dateToFormat } from '@helpers/utils/date-to-format';
import { DiscountConditionSummaryResponse } from '@model/response/discount-condition-summary.response';
import useOverflowYHidden from '@helpers/hooks/useOverflowYHidden';
import getYearString from '@helpers/utils/get-year-string';
import { Locale } from '@model/local.types';

interface ModalDiscountConditionSummaryProps {
  asOfYear: number | null;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  visible: boolean;
}

interface DataSource {
  label: string;
  labelRemark: string;
  summaryDiscountPass: boolean;
  summaryDiscount: number;
  summaryDiscountDate: string | null;
}

const ModalDiscountConditionSummary = ({ asOfYear, onCancel, visible }: ModalDiscountConditionSummaryProps): React.ReactElement => {
  const { t, i18n } = useTranslation(['title']);
  const [loading, loadingToggle] = useTrueFalse();
  const [data, setData] = useState<DiscountConditionSummaryResponse>();
  const [dataSource, setDataSource] = useState<DataSource[]>();
  const modalClassName = useOverflowYHidden(visible);

  const format = useMemo(() => {
    dayjs.locale(i18n.language);
    return `DD MMM ${i18n.language === 'th' ? 'BBBB' : 'YYYY'}`;
  }, [i18n.language]);

  useEffect(() => {
    if (!asOfYear || !visible) return;
    loadingToggle();
    DiscountConditionSummaryService.getDiscountConditionSummary(asOfYear)
      .pipe(finalize(() => loadingToggle()))
      .subscribe({
        next: (r) => {
          setData(r);
          if (!r.showOnlySummary) {
            setDataSource([
              {
                label: `${t('label:discount_condition_summary.discount_condition_summary', {
                  percentage: r.percentage,
                  date: getDate(r.finalDiscountDate),
                })}`,
                labelRemark: t('label:discount_condition_summary.discount_condition_summary_remark'),
                summaryDiscountPass: r.summaryDiscountPass,
                summaryDiscount: r.summaryDiscount,
                summaryDiscountDate: r.summaryDiscountDate,
              },
            ]);
          }
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asOfYear, visible]);

  const getDate = (date?: string | null) => (date ? dateToFormat(new Date(date), format) : '-');

  const LabelView = ({ label, labelRemark }: DataSource) => (
    <>
      <div>{label}</div>
      <div className="text-xs text-[#9DA6AD]">{labelRemark}</div>
    </>
  );

  const StatusRemark = ({ summaryDiscount, summaryDiscountDate }: Partial<DataSource>) => (
    <>
      {`(${t('label:discount_condition_summary.entered_data', { percent: summaryDiscount?.toFixed(2) })}  ${
        summaryDiscountDate
          ? t('label:discount_condition_summary.approved_date', { date: getDate(summaryDiscountDate) })
          : t('label:discount_condition_summary.not_approved')
      })`}
    </>
  );

  const StatusView = ({ summaryDiscountPass, summaryDiscount, summaryDiscountDate }: DataSource) => (
    <>
      <div className="flex gap-2 items-center">
        <StatusIcon size="sm" type={summaryDiscountPass ? 'complete' : 'need-urgent-action'} />
        <span>{t(`label:discount_condition_summary.${summaryDiscountPass ? '' : 'not_'}pass`)}</span>
      </div>
      <div className="flex gap-2 items-center">
        <span className="mr-[14px]" />
        <span className="text-xs text-[#9DA6AD]">
          <StatusRemark summaryDiscount={summaryDiscount} summaryDiscountDate={summaryDiscountDate} />
        </span>
      </div>
    </>
  );

  const columns: Column[] = useMemo(() => {
    return [
      {
        title: t('title:discount_condition_summary.discount_condition'),
        width: '60%',
        render: (value) => LabelView(value),
      },
      {
        title: t('title:discount_condition_summary.status'),
        width: '40%',
        render: (value) => StatusView(value),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const modalTitle = useMemo(() => {
    if (!data) return '';
    const tomorrowFinalDate = new Date(data.finalDiscountDate);
    tomorrowFinalDate.setDate(new Date(data.finalDiscountDate).getDate() + 1);
    return data?.showOnlySummary
      ? t('title:discount_condition_summary.modal_title_version_2', {
          asOfYear: getYearString(data.asOfYear, i18n.language as Locale),
          date: getDate(tomorrowFinalDate.toDateString()),
        })
      : t('title:discount_condition_summary.modal_title_version_1', {
          asOfYear: getYearString(data.asOfYear, i18n.language as Locale),
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asOfYear, data, i18n.language, t]);

  return (
    <Modal
      data-testid="discount-condition-summary-modal"
      visible={visible}
      className={`${modalClassName}`}
      onCancel={onCancel}
      title={
        <div className="flex flex-row gap-2 items-center">
          <Icon name="rule" size="text-3xl" color="text-orange-500" />
          <span className="text-[16px] font-bold whitespace-pre-line">{modalTitle}</span>
        </div>
      }
      width={550}
      footer={
        <Modal.Footer>
          <div className="flex justify-end py-2 px-4 border-t-[1px] border-[#e4e7eb]">
            <Button color="secondary" onClick={onCancel}>
              {t('button:close')}
            </Button>
          </div>
        </Modal.Footer>
      }
    >
      <div className="flex flex-col gap-4 pb-2">
        {!data?.showOnlySummary ? (
          <>
            <div className="flex">
              <div className="w-[60%]">
                <div>{t('label:discount_condition_summary.entered_data_status_1')}</div>
                <div>{t('label:discount_condition_summary.entered_data_status_2')}</div>
              </div>
              {data && (
                <div className="pl-[8px] w-[40%]">
                  <div className="flex gap-2 items-center">
                    <StatusIcon size="sm" type={data.draftDiscountPass ? 'complete' : 'need-urgent-action'} />
                    <span>{`${data.draftDiscount?.toFixed(2)}%`}</span>
                  </div>
                </div>
              )}
            </div>
            <Table columns={columns} dataSource={dataSource} loading={loading} />
          </>
        ) : (
          <div>
            <div className="flex gap-2 items-center px-[14px]">
              <StatusIcon size="sm" type={data.summaryDiscountPass ? 'complete' : 'need-urgent-action'} />
              <div>
                {t(`label:discount_condition_summary.${data.summaryDiscountPass ? '' : 'not_'}pass_follow_by_discount_condition`, {
                  date: getDate(data.finalDiscountDate),
                })}
              </div>
            </div>
            <div className="flex gap-2 items-center px-[14px]">
              <span className="mr-[14px]" />
              <StatusRemark summaryDiscount={data.summaryDiscount} summaryDiscountDate={data.summaryDiscountDate} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ModalDiscountConditionSummary;

import { useReportState } from '@context/Report.context';
import { Breadcrumb, BreadcrumbRouteProps, Content } from '@mis/sushi-tailwind-react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-location';
import StateOutlet from './StateOutlet';
import useWebsocket from '@helpers/hooks/useWebsocket';
import ReportOutlet from './ReportOutlet';
import { ReportResponse } from '@model/response/report.response';

interface ReportContentProps {
  collapsed: boolean;
  children: React.ReactNode;
  routes: BreadcrumbRouteProps[];
  reportId: number | string;
  report: ReportResponse;
}
export default function ReportContent({ collapsed, children, routes, reportId, report }: ReportContentProps): React.ReactElement | null {
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);
  const { currentNode } = useReportState();
  const { connect, subscribe, disconnect, unsubscribe, getHeaders } = useWebsocket();
  useEffect(() => {
    connect(() => {
      setConnected(true);
    });
    window.onbeforeunload = (e) => {
      e.preventDefault();
      if (disconnect) {
        console.log('disconnect node');
        disconnect();
      }
      // return '';
    };
    return () => {
      if (disconnect) {
        console.log('disconnect node');
        disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickBreadcrumb = (m: BreadcrumbRouteProps) => {
    if (m.href) {
      let href = m.label === 'home' ? m.href : `/report/${reportId}${m.href}`;
      if (m.href === '/') {
        const year = (report as ReportResponse).asOfYear;
        href = `/?year=${year}`;
      }
      navigate({ to: href, replace: true });
    }
  };

  if (!reportId || !currentNode) {
    return null;
  }

  return (
    <Content
      id="report-content"
      className={classNames('flex-col px-6 mb-10 md:px-10 transition-all duration-300', collapsed ? undefined : 'ml-67')}
    >
      <div className="relative w-full">
        <div className="container flex flex-col gap-4 px-3 pb-5 mx-auto max-w-[964px] md:px-2">
          <Breadcrumb routes={routes} onClick={onClickBreadcrumb} className="hidden py-3 md:flex"></Breadcrumb>
          <ReportOutlet
            connected={connected}
            subscribe={subscribe}
            reportId={reportId}
            currentNode={currentNode}
            unsubscribe={unsubscribe}
            getHeaders={getHeaders}
          >
            <StateOutlet>{children}</StateOutlet>
          </ReportOutlet>
        </div>
      </div>
    </Content>
  );
}

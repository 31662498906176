import React, { useReducer } from 'react';

export type ActionType = 'SET_CURRENT_YEAR';

type Action =
  | { type: 'SET_CURRENT_DATE_TIME'; payload: Date | null }
  | { type: 'SET_CURRENT_YEAR'; payload: number | null }
  | { type: 'SET_DEFAULT_YEAR'; payload: number | null }
  | { type: 'SET_CURRENT_REPORT_ID'; payload: number | null };
type Dispatch = (action: Action) => void;
type State = {
  currentDateTime: Date | null;
  currentYear: number | null;
  currentReportId: number | null;
  defaultYear: number | null;
};

const LandingStateContext = React.createContext<State | undefined>(undefined);
const LandingDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_CURRENT_DATE_TIME':
      return { ...state, currentDateTime: action.payload };
    case 'SET_CURRENT_YEAR':
      return { ...state, currentYear: action.payload };
    case 'SET_CURRENT_REPORT_ID':
      return { ...state, currentReportId: action.payload };
    case 'SET_DEFAULT_YEAR': {
      return { ...state, defaultYear: action.payload };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface LandingProviderProps {
  currentYear: number | null;
  currentDateTime: Date | null;
  defaultYear: number | null;
}

const LandingProvider: React.FC<LandingProviderProps> = ({ children, currentYear, currentDateTime, defaultYear }) => {
  const [state, dispatch] = useReducer(reducer, { currentYear, currentReportId: null, currentDateTime, defaultYear });
  return (
    <LandingStateContext.Provider value={state}>
      <LandingDispatchContext.Provider value={dispatch}>{children}</LandingDispatchContext.Provider>
    </LandingStateContext.Provider>
  );
};

const useLandingState = (): State => {
  const context = React.useContext(LandingStateContext);
  if (context === undefined) {
    throw new Error('useLandingState must be used within a LandingProvider');
  }
  return context;
};
const useLandingDispatch = (): Dispatch => {
  const context = React.useContext(LandingDispatchContext);
  if (context === undefined) {
    throw new Error('useLandingDispatch must be used within a LandingProvider');
  }
  return context;
};

export { LandingProvider, useLandingState, useLandingDispatch };

import React, { useEffect, useRef, useState } from 'react';
import { Icon, Popover } from '@mis/sushi-tailwind-react';
import { ContactCardService } from '@core/services/contact-card.service';
import { ContactCard as CC } from '@model/contact-card';
import './Contact.css';

export const ContactCard = (): React.ReactElement => {
  const [contacts, setContacts] = useState<CC[]>([]);
  const popcornRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    ContactCardService.getContactCard().subscribe({
      next: (response) => {
        setContacts(response);
      },
    });
  }, []);

  return (
    <>
      <Popover
        title="ข้อมูลติดต่อ Relationship Manager (RM)"
        style={{ minWidth: 270 }}
        trigger={'click'}
        showArrow={false}
        overlayClassName="rm-tooltip"
        placement="bottomLeft"
        offset={[-240, 0]}
        content={
          <div>
            {contacts.map((contact) => {
              return (
                <div key={contact.id}>
                  <div className="flex gap-1">
                    ชื่อ-สกุล: <div className={'font-bold'}>{contact.nameTh}</div>
                  </div>
                  <div className="flex gap-1">
                    เบอร์โทร office: <div className={'font-bold'}>{contact.officeNumber}</div>
                  </div>
                  <div className="flex gap-1">
                    เบอร์โทรศัพท์: <div className={'font-bold'}>{contact.mobile}</div>
                  </div>
                  <div className="flex gap-1">
                    อีเมล: <div className={'font-bold'}>{contact.email}</div>
                  </div>
                </div>
              );
            })}
          </div>
        }
      >
        <span className="flex flex-row items-center hover:opacity-50 cursor-pointer" ref={popcornRef}>
          <Icon name="account_circle" color="text-tertiary" size="text-2xl" type="outlined" />
        </span>
      </Popover>
    </>
  );
};

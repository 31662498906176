import InitialReportData from '@components/initial-report-data/InitialReportData';
import UserCurrentReportNode from '@components/user-current-report-node/UserCurrentReportNode';
import { useProfileState } from '@context/Profile.context';
import { useReportState } from '@context/Report.context';
import { ReportStatusProvider } from '@context/ReportStatus.context';
import { Spin } from '@mis/sushi-tailwind-react';
import React from 'react';
import { useRouter, Outlet } from 'react-location';

type StateOutletProps = {
  children?: React.ReactNode;
};

const StateOutlet = ({ children }: StateOutletProps): React.ReactElement => {
  const router = useRouter();
  const { currentUserReports } = useReportState();
  const { profile } = useProfileState();
  if (router.pending) {
    return (
      <div className="flex justify-center items-center">
        <Spin />
      </div>
    );
  }
  return (
    <ReportStatusProvider currentUserReports={currentUserReports} profile={profile}>
      <UserCurrentReportNode />
      <InitialReportData>{children ? children : <Outlet />}</InitialReportData>
    </ReportStatusProvider>
  );
};

export default StateOutlet;

import { useEffect } from 'react';

export default function useOverflowYHidden(visible: boolean | undefined): string {
  const modalClassName = visible ? '!overflow-y-hidden modal-open' : '';

  useEffect(() => {
    const allWithClass = Array.from(document.getElementsByClassName('modal-open'));
    if (!visible && allWithClass.length > 0) {
      return;
    }

    if (visible && !document.body.classList.contains('overflow-y-hidden')) {
      document.body.className += ' overflow-y-hidden';
    } else if (!visible && document.body.classList.contains('overflow-y-hidden')) {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [modalClassName, visible]);

  return modalClassName;
}

import { AuthWrapperChildrenProps, withAuthWrapper } from '@components/auth-wrapper/AuthWrapper';
import DisclaimerGuard from '@components/disclaimer-guard/DisclaimerGuard';
import Icon from '@components/icon/Icon';
import PendingElement from '@components/pending-element/PendingElement';
import { env } from '@core/env';
import { AuthService } from '@core/services/auth.service';
import { ReportService } from '@core/services/report.service';
import { TokenService } from '@core/services/token.service';
import { Button, Modal } from '@mis/sushi-tailwind-react';
import { UserRole } from '@model/enum/user-role.enum';
import { Profile } from '@model/profile';
import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-location';

const LDPLandingFlow = () => {
  const {
    search: { node, section },
    params: { asOfYear },
  } = useMatch();

  useEffect(() => {
    if (asOfYear) {
      ReportService.getReportInfo(Number(asOfYear)).subscribe({
        next: (response) => {
          redirect(response.id);
        },
        error: () => {
          window.location.href = env.setLinkURL;
        },
      });
    } else {
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = (reportId?: number) => {
    const refNode = node;
    const pathname = reportId ? `/report/${reportId}/${refNode}` : '/';
    const url = new URL(pathname, window.location.origin);
    let href = url.href;
    if (section) {
      href += `#${section}`;
    }
    window.location.href = href;
  };

  return <PendingElement></PendingElement>;
};

interface RoleGuardProps {
  children?: React.ReactNode;
}

const RoleGuard = ({ children }: RoleGuardProps): React.ReactElement | null => {
  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    const jwt = TokenService.getToken();
    AuthService.authentication(jwt as string).subscribe({
      next: (response) => {
        setProfile(response);
      },
    });
  }, []);

  const onClose = () => {
    window.location.href = env.setLinkURL;
  };

  if (profile) {
    if (profile.roles.some((role) => role.roleName === UserRole.ESG_CREATOR)) {
      return <>{children}</>;
    } else {
      return (
        <div>
          <Modal
            title={
              <div className="flex flex-row gap-3 items-center">
                <Icon.Alert height="24" width="27" />
                <span>ไม่สามารถเข้าสู่ระบบได้</span>
              </div>
            }
            onCancel={onClose}
            visible={true}
            footer={
              <Modal.Footer>
                <div className="flex justify-end px-4 pt-2 pb-4 border-t border-t-dark-6">
                  <Button color="secondary" onClick={onClose}>
                    ปิด
                  </Button>
                </div>
              </Modal.Footer>
            }
            width={381}
          >
            <div className="pl-[35px]">
              เนื่องจากคุณยังไม่มีสิทธิในการเข้าใช้งานระบบ ESG Data Platform กรุณาติดต่อผู้ประสานงานของบริษัทท่านเพื่อกำหนดบทบาท ESG Data
              Platform Creator/ ESG Data Platform Approver
            </div>
          </Modal>
        </div>
      );
    }
  }
  return null;
};

const LDPLandingPage = ({ isSetData }: AuthWrapperChildrenProps): React.ReactElement => {
  if (isSetData === true) {
    return (
      <RoleGuard>
        <DisclaimerGuard>
          <LDPLandingFlow />
        </DisclaimerGuard>
      </RoleGuard>
    );
  }
  return <PendingElement></PendingElement>;
};

export default withAuthWrapper(LDPLandingPage);

import { ReportMainNodeName, ReportEnvironmentType, ReportSocialType, ReportGovernanceType } from '@model/report-node';

export type SubNodeType = ReportSocialType & ReportEnvironmentType & ReportGovernanceType;

export interface ReportNodeDetail<N = ReportMainNodeName, S = SubNodeType> {
  no: number;
  nodeName: N;
  subNodes: { nodeName: S }[];
}

export type ReportNodeType = (
  | ReportNodeDetail<'environment', ReportEnvironmentType>
  | ReportNodeDetail<'social', ReportSocialType>
  | ReportNodeDetail<'governance', ReportGovernanceType>
  | ReportNodeDetail<'sendToApprover'>
  | ReportNodeDetail<'approveReport'>
  | ReportNodeDetail<'reportScope'>
)[];

export const reportNodes = (): ReportNodeType => [
  {
    no: 1,
    nodeName: 'reportScope',
    subNodes: [],
  },
  {
    no: 2,
    nodeName: 'environment',
    subNodes: [
      { nodeName: 'environmentalMgmt' },
      { nodeName: 'energyMgmt' },
      { nodeName: 'waterMgmt' },
      { nodeName: 'wasteMgmt' },
      { nodeName: 'ghGasMgmt' },
    ],
  },
  {
    no: 3,
    nodeName: 'social',
    subNodes: [{ nodeName: 'humanRights' }, { nodeName: 'laborRights' }],
  },
  {
    no: 4,
    nodeName: 'governance',
    subNodes: [
      { nodeName: 'corporateGovernancePolicy' },
      { nodeName: 'corporateGovernance' },
      { nodeName: 'resultOfCorporateGovernance' },
      { nodeName: 'sustainabilityPolicy' },
    ],
  },
  {
    no: 5,
    nodeName: 'sendToApprover',
    subNodes: [],
  },
  {
    no: 5,
    nodeName: 'approveReport',
    subNodes: [],
  },
];

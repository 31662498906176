import { SubNodeType } from '@helpers/utils/report-node';
import { ReportInfo, ReportInfoDetail } from '@model/report-info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableDataDetail from './TableDataDetail';

export type TableRowSubNodeProps = {
  nodeName: 'environment' | 'governance' | 'social' | 'reportScope' | 'approveReport' | 'sendToApprover';
  subNodeName: SubNodeType;
  reportInfo: ReportInfo;
};

export default function TableRowSubNode({ nodeName, subNodeName, reportInfo }: TableRowSubNodeProps): React.ReactElement {
  const { t } = useTranslation();
  let link = '/';
  let updateDate = null;
  let updateUser = null;
  if (reportInfo && reportInfo.id) {
    link = `/report/${reportInfo.id}/${subNodeName}`;
  }
  if (reportInfo && reportInfo[nodeName] && reportInfo[nodeName][subNodeName]) {
    const detail = reportInfo[nodeName][subNodeName] as ReportInfoDetail;
    updateDate = detail.updateDate;
    if (detail.updateByUser) {
      updateUser = `${detail.updateByUser.firstNameTh} ${detail.updateByUser.lastNameTh}`;
    }
  }
  const hasLink = link && link != null;
  return (
    <tr key={nodeName}>
      <td className="py-1">
        <ol className="list-disc list-inside">
          <li>
            <span className={hasLink ? 'text-[#212121]' : 'text-black/[.35]'}>{t(`reportNode.${subNodeName as string}.title`)}</span>
          </li>
        </ol>
      </td>
      <TableDataDetail link={link} updateDate={updateDate} updateUser={updateUser} nodeName={subNodeName} />
    </tr>
  );
}

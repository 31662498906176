import HeaderLayout from '@layouts/header/HeaderLayout';
import React from 'react';
import { Outlet } from 'react-location';
import { Content, Layout } from '@mis/sushi-tailwind-react';
import InitialData from '@components/initial-data/InitialData';

const PageLayout: React.FC = ({ children }) => {
  return (
    <InitialData>
      <Layout className="mt-12 min-h-screen">
        <HeaderLayout />
        <Content className="container px-3 pb-5 mx-auto max-w-[1096px] md:px-2">
          <div className="flex flex-col gap-6 py-6 w-full">{children ? children : <Outlet />}</div>
        </Content>
      </Layout>
    </InitialData>
  );
};

export const pageLayout = (WrappedComponent: React.ComponentType<Record<string, unknown>>) => {
  return function layout(props: Record<string, unknown>): JSX.Element {
    return (
      <PageLayout>
        <WrappedComponent {...props} />
      </PageLayout>
    );
  };
};

export default PageLayout;

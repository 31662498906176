import { ReportNodeDetail } from '@helpers/utils/report-node';
import { ReportInfo, ReportInfoDetail } from '@model/report-info';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TableDataDetail from './TableDataDetail';
import TableRowSubNode, { TableRowSubNodeProps } from './TableRowSubNode';
import { Link } from 'react-location';
import { ReportStatusResponse } from '@model/response/report-status.response';

interface TableRowProps extends Pick<ReportNodeDetail, 'subNodes'>, Pick<TableRowSubNodeProps, 'nodeName'> {
  reportInfo: ReportInfo;
  reportStatus: ReportStatusResponse;
}

type TableDataHeaderProps = Pick<TableRowProps, 'nodeName'> & { reportInfo: ReportInfo; reportStatus: ReportStatusResponse };

const TableDataHeader = ({ nodeName, reportInfo, reportStatus }: TableDataHeaderProps) => {
  const { t } = useTranslation();
  const colSpan = ['reportScope'].includes(nodeName) ? 1 : 3;
  if (['sendToApprover', 'approveReport'].includes(nodeName)) {
    if (
      (nodeName === 'sendToApprover' && ['COMPLETE'].includes(reportStatus.draftCompleteStatus)) ||
      (nodeName === 'approveReport' &&
        ['COMPLETE'].includes(reportStatus.draftCompleteStatus) &&
        ['INCOMPLETE', 'COMPLETE'].includes(reportStatus.onProcessStatus) &&
        !reportStatus.rejectedReason)
    ) {
      return (
        <Link to={`/report/${reportInfo.id}/${nodeName}`} data-test={`${nodeName}-link`}>
          <td colSpan={colSpan} className="py-2 font-bold text-[#00A7CC]">
            {t(`reportNode.${nodeName}.title`)}
          </td>
        </Link>
      );
    }
  }
  return (
    <td colSpan={colSpan} className="py-2 font-medium">
      {t(`reportNode.${nodeName}.title`)}
    </td>
  );
};

type TableDataProps = Pick<TableRowProps, 'reportInfo' | 'nodeName'>;

const TableData = ({ reportInfo, nodeName }: TableDataProps) => {
  let link = '/';
  let updateDate = null;
  let updateUser = null;
  if (reportInfo && reportInfo.id) {
    link = `/report/${reportInfo.id}/${nodeName}`;
  }
  if (reportInfo && reportInfo[nodeName] && reportInfo[nodeName]) {
    const detail = reportInfo[nodeName] as ReportInfoDetail;
    updateDate = detail.updateDate;
    if (detail.updateByUser) {
      updateUser = `${detail.updateByUser.firstNameTh} ${detail.updateByUser.lastNameTh}`;
    }
  }
  return <TableDataDetail link={link} updateDate={updateDate} updateUser={updateUser} nodeName={nodeName} />;
};

export default function TableRow({ nodeName, subNodes, reportInfo, reportStatus }: TableRowProps): React.ReactElement {
  return (
    <>
      <tr>
        <TableDataHeader nodeName={nodeName} reportInfo={reportInfo} reportStatus={reportStatus} />
        {['reportScope'].includes(nodeName) && <TableData nodeName={nodeName} reportInfo={reportInfo} />}
      </tr>
      {Array.isArray(subNodes) &&
        subNodes.map((subNode) => {
          return <TableRowSubNode key={subNode.nodeName} nodeName={nodeName} subNodeName={subNode.nodeName} reportInfo={reportInfo} />;
        })}
    </>
  );
}

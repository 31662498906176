import { StatusIconType } from '@mis/sushi-tailwind-react';
import { ReportNodeStatusType } from '@model/report-node';

export default function nodeStatusToStatusIconType(nodeStatus: ReportNodeStatusType): StatusIconType | undefined {
  switch (nodeStatus) {
    case 'ACTIVE': {
      return 'wait-for-action';
    }
    case 'COMPLETE': {
      return 'complete';
    }
    case 'INCOMPLETE': {
      return 'work-in-progress';
    }
    default: {
      return;
    }
  }
}

import React from 'react';
import { Button, Modal } from '@mis/sushi-tailwind-react';
import { useTranslation } from 'react-i18next';
import Icon from '@components/icon/Icon';
import useOverflowYHidden from '@helpers/hooks/useOverflowYHidden';

interface ModalAlertProps {
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  visible: boolean;
}

const ModalAlert = ({ visible, onCancel }: ModalAlertProps): React.ReactElement => {
  const { t } = useTranslation(['title']);
  const modalClassName = useOverflowYHidden(visible);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={
        <div className="flex gap-2 items-center">
          <Icon.Alert width="20px" height="22px" /> {t('title:one_report_forbidden.title')}
        </div>
      }
      data-testid="modal-alert"
      width={381}
      className={`${modalClassName}`}
      footer={
        <Modal.Footer>
          <div className="flex justify-end p-2 px-4 border-t-[1px] border-[#e4e7eb]">
            <Button color="secondary" data-testid={'btn-close'} onClick={onCancel}>
              {t('button:close')}
            </Button>
          </div>
        </Modal.Footer>
      }
    >
      <div className="pb-2 pl-8">{t('title:one_report_forbidden.content')}</div>
    </Modal>
  );
};
export default ModalAlert;

import { AuthWrapperChildrenProps, withAuthWrapper } from '@components/auth-wrapper/AuthWrapper';
import PendingElement from '@components/pending-element/PendingElement';
import { env } from '@core/env';
import React from 'react';

const AuthPage = ({ isSetData, redirectUrl }: AuthWrapperChildrenProps) => {
  console.log('isSetData: ', isSetData);
  if (isSetData === true) {
    window.location.href = redirectUrl || '/';
  }
  if (isSetData === false) {
    window.location.href = env.setLinkURL;
  }
  return <PendingElement />;
};

export default withAuthWrapper(AuthPage);

import { Radio, RadioProps } from '@mis/sushi-tailwind-react';
import classNames from 'classnames';
import { Field, FieldAttributes, FieldInputProps, FieldProps } from 'formik';
import React from 'react';

export interface RadioField<RadioValueType> {
  value: RadioValueType;
  label: string;
}
export interface RadioGroupProps<RadioValueType = string> {
  type: string;
  items: RadioField<RadioValueType>[];
  disabled?: boolean;
  required?: boolean;
  position?: 'vertical' | 'horizontal' | null;
  errorWithoutTouch?: boolean;
  onChange?: RadioProps['onChange'];
}

export const RadioGroup = ({
  items,
  type,
  disabled = false,
  required,
  position,
  errorWithoutTouch,
  ...props
}: RadioGroupProps): JSX.Element => {
  const onValueChange =
    (onChange: FieldInputProps<string>['onChange']): RadioProps['onChange'] =>
    (e) => {
      onChange(e);
      props.onChange && props.onChange(e);
    };
  const validate = (value: string) => {
    if (required && !value) {
      return 'required';
    }
    return;
  };

  return (
    <div className="flex justify-between">
      <div className={classNames('flex gap-1', position === 'horizontal' ? 'flex-row' : 'flex-col')}>
        {items.map(({ value, label }) => (
          <Field validate={validate} name={type} key={value}>
            {({ field, meta: { error, touched } }: FieldAttributes<FieldProps<AnyValue>>) => {
              const isError = errorWithoutTouch ? error : error && touched;
              return (
                <Radio
                  {...field}
                  onChange={onValueChange(field.onChange)}
                  value={value}
                  disabled={disabled}
                  data-testid={`${type}${value}Radio`}
                  checked={field.value === value}
                  className={isError ? 'border-danger-900' : undefined}
                >
                  {label}
                </Radio>
              );
            }}
          </Field>
        ))}
      </div>
    </div>
  );
};

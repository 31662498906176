import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BullIsComing, BullIsComingEventType, BullIsComingProcessProps, ZWIZAccessTokenCallbackProps } from '@mis/the-bull-is-coming';
import { env } from '@core/env';
import { TokenService } from '@core/services/token.service';
import { useProjectStateState } from '@context/ProjectState.context';
import { useLayoutState } from '@context/Layout.context';
import { useProfileState } from '@context/Profile.context';
import { UserRole } from '@model/enum/user-role.enum';

const TheBull = (): React.ReactElement | null => {
  const { disclaimer } = useProjectStateState();
  const [showBackTop, setShowBackTop] = useState(false);
  const { notificationOpen } = useLayoutState();
  const { profile } = useProfileState();
  const [dialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    const scroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 150) {
        setShowBackTop(true);
      } else {
        setShowBackTop(false);
      }
    };
    document.addEventListener('scroll', scroll);
    return () => {
      document.removeEventListener('scroll', scroll);
    };
  }, []);
  const checkHome = useCallback(() => {
    const pathname = window.location.pathname;
    return pathname === '/';
  }, []);
  const showBull = useMemo(() => {
    const roles = profile?.roles ?? [];
    if (
      disclaimer &&
      roles.some((role) => [UserRole.ESG_APPROVER, UserRole.ESG_CREATOR].includes(role.roleName as UserRole)) &&
      !roles.some((role) => [UserRole.ESG_REG].includes(role.roleName as UserRole))
    ) {
      return true;
    }
    return false;
  }, [disclaimer, profile?.roles]);
  const zwizStyle = useMemo<React.CSSProperties>(() => {
    const isHome = checkHome();
    if (showBackTop && notificationOpen) {
      return { bottom: '150px', right: '8px' };
    }
    if (showBackTop) {
      return { bottom: '90px', right: '-3px' };
    }
    if (notificationOpen) {
      return { bottom: '100px', right: '-3px' };
    }
    return { bottom: isHome ? '50px' : '90px', right: '-3px' };
  }, [checkHome, notificationOpen, showBackTop]);

  const chatbotStyle = useMemo<React.CSSProperties>(() => {
    const isHome = checkHome();
    if (showBackTop && notificationOpen) {
      return { bottom: '160px', right: '8px' };
    }
    if (showBackTop) {
      return { bottom: '100px', right: '8px' };
    }
    if (notificationOpen) {
      return { bottom: '115px', right: '8px' };
    }
    if (dialogOpen) {
      return { bottom: isHome ? '60px' : '100px', right: '8px' };
    }
    return { bottom: '60px', right: '8px' };
  }, [checkHome, showBackTop, notificationOpen, dialogOpen]);

  const handleEvent = useCallback(
    (event: BullIsComingEventType) => {
      setDialogOpen(event.event !== 'CLOSE_DIALOG');
    },
    [setDialogOpen]
  );

  const eventRequestAuthToken: ZWIZAccessTokenCallbackProps['eventRequestAuthToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestRefreshAuthenToken: BullIsComingProcessProps['eventRequestRefreshAuthenToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestRefreshISPToken: BullIsComingProcessProps['eventRequestRefreshISPToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestChatBot: BullIsComingProcessProps['eventRequestChatBot'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback({ token: data.data.token });
      },
    });
  };

  if (!showBull) {
    return null;
  }
  return (
    <BullIsComing
      zwizEnv={env.zwiz.env}
      zwizClientId="set-link"
      zwizClientSecret={env.zwiz.clientSecret}
      zwizStyle={zwizStyle}
      chatbotUrl={env.chatbot.url}
      chatbotAgentId={env.chatbot.agentID}
      chatbotDLKey={env.chatbot.dlKey}
      chatbotStyle={chatbotStyle}
      event={handleEvent}
      eventRequestAuthToken={eventRequestAuthToken}
      eventRequestRefreshAuthenToken={eventRequestRefreshAuthenToken}
      eventRequestRefreshISPToken={eventRequestRefreshISPToken}
      eventRequestChatBot={eventRequestChatBot}
    />
  );
};

export default TheBull;

import PageLayout from '@layouts/layout/PageLayout';
import { Spin } from '@mis/sushi-tailwind-react';
import React from 'react';

const PendingElement = (): React.ReactElement => {
  return (
    <PageLayout>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <Spin />
      </div>
    </PageLayout>
  );
};

export default PendingElement;

import { env } from '@core/env';
import { Spin } from '@mis/sushi-tailwind-react';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useMatch } from 'react-location';
const ErrorRouteFetch = (): React.ReactElement => {
  const match = useMatch();
  useEffect(() => {
    const { error } = match;
    if (error && (error as AxiosError).response) {
      const code = (error as AxiosError).response?.status;
      if (code === 401) {
        window.location.href = env.setLinkURL;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      <Spin />
    </div>
  );
};

export default ErrorRouteFetch;

import { Card, CardProps, Icon, Tooltip } from '@mis/sushi-tailwind-react';
import React from 'react';

interface CardTooltipProps extends CardProps {
  tooltip?: string;
}

const CardTooltip = (props: CardTooltipProps): React.ReactElement => {
  const { title, tooltip, children } = props;
  return (
    <Card
      {...props}
      title={
        <div className="flex flex-row gap-1 justify-center items-center">
          {title}
          {tooltip && (
            <div style={{ paddingTop: '5px', paddingLeft: '5px' }} className="cursor-default">
              <Tooltip placement="topLeft" width={420} title={tooltip}>
                <Icon name="info" size="text-lg" />
              </Tooltip>
            </div>
          )}
        </div>
      }
    >
      {children}
    </Card>
  );
};

export default CardTooltip;

import { Modal, Button } from '@mis/sushi-tailwind-react';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useOverflowYHidden from '@helpers/hooks/useOverflowYHidden';

interface ModalConfirmProps {
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  onOk?: MouseEventHandler<HTMLButtonElement> | undefined;
  title?: React.ReactNode;
  visible: boolean;
  children?: React.ReactNode;
  id?: string;
  disableOk?: boolean;
  hideCancel?: boolean;
  hideOk?: boolean;
  confirmLoading?: boolean;
  width?: number | string;
  ['data-testid']?: string;
  footer?: React.ReactNode;
  closable?: boolean;
  okText?: string;
}

const ModalConfirm = ({
  title,
  visible,
  children,
  id,
  onCancel,
  onOk,
  hideCancel,
  disableOk,
  footer,
  confirmLoading,
  width,
  closable,
  okText,
  ...props
}: ModalConfirmProps): React.ReactElement => {
  const { t } = useTranslation(['button']);
  const modalClassName = useOverflowYHidden(visible);
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={width}
      title={title}
      closable={closable}
      data-testid={props['data-testid']}
      className={`${modalClassName}`}
      footer={
        footer ?? (
          <Modal.Footer>
            <div
              className={classNames('flex px-4 pt-2 pb-4 border-t-[1px] border-[#e4e7eb]', !hideCancel ? 'justify-between' : 'justify-end')}
            >
              {!hideCancel && (
                <Button color="secondary" type="button" data-testid={id ? `btn-${id}-cancel` : 'btn-cancel-modal'} onClick={onCancel}>
                  {t('button:cancel')}
                </Button>
              )}
              <Button
                type="button"
                loading={confirmLoading}
                color="primary"
                data-testid={id ? `btn-${id}-confirm` : 'btn-confirm-modal'}
                onClick={onOk}
                disabled={disableOk}
              >
                {okText ?? t('button:confirm')}
              </Button>
            </div>
          </Modal.Footer>
        )
      }
    >
      {children}
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  width: 480,
};

export default ModalConfirm;

import Icon from '@components/icon/Icon';
import ModalAlert from '@components/modal-alert/ModalAlert';
import useLinkToLDP from '@helpers/hooks/useLinkToLdp';
import { Button } from '@mis/sushi-tailwind-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface LinkToLDPProps {
  btnColor?: 'primary' | 'secondary';
}

export default function LinkToLDP({ btnColor }: LinkToLDPProps): React.ReactElement {
  const { t } = useTranslation(['label', 'description', 'title']);
  const [visible, onVisibleTrigger, onClickExternalLink] = useLinkToLDP();

  return (
    <>
      <Button data-testid="btn-link-to-ldp" color={btnColor || 'primary'} type="button" onClick={onClickExternalLink}>
        <div className="flex gap-1 justify-between items-center">
          <span>{t('button:link_to_ldp')}</span>
          <Icon.ExternalLink fill="#212121" />
        </div>
      </Button>
      <ModalAlert visible={visible} onCancel={onVisibleTrigger} />
    </>
  );
}

import React, { useEffect, useMemo } from 'react';
import { Header } from '@mis/sushi-tailwind-react';
import setLogo from '@assets/images/set-logo.png';
import { useTranslation } from 'react-i18next';
import { useProfileState } from '@context/Profile.context';
import dayjs from 'dayjs';
import { ContactCard } from './Contact';

const HeaderLayout = (): React.ReactElement | null => {
  const { t, i18n } = useTranslation();
  const { profile } = useProfileState();
  const roles = useMemo<string>(() => {
    if (profile && Array.isArray(profile.roles)) {
      return profile.roles
        .filter((role) => role.roleName.includes('ESG'))
        .map((role) => role.roleDescEng)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', ');
    }
    return '';
  }, [profile]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  return (
    <Header className="top-0 z-[99] py-4 w-[100vw] h-fit min-h-[48px] md:fixed md:py-0 md:h-12" position="fixed">
      <div className="flex flex-col justify-center py-2 w-full md:flex-row md:justify-between md:py-0">
        <div className="flex gap-4 justify-center items-center w-full md:justify-start">
          <img className="w-[88px] h-[25px]" src={setLogo} alt="SET Logo" />
          <span className="font-sarabun font-extrabold">{t('title')}</span>
        </div>
        <div className="flex flex-col gap-4 justify-center items-center w-full md:flex-row md:justify-end">
          <div className="flex flex-col items-center md:items-end">
            <span className="font-sarabun text-[14px] text-right truncate md:max-w-[200px]">
              {profile ? `Welcome, ${profile.firstName}` : ''}
            </span>
            <span className="hidden max-w-full text-[10px] text-[#9da6ad] md:block md:max-w-[200px] md:truncate">{roles}</span>
          </div>
          {profile && (
            <div className="flex flex-row gap-2">
              {/* <Language /> */}
              <ContactCard />
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};

export default HeaderLayout;

import CardLoading from '@components/card-loading/CardLoading';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import { Button, Card } from '@mis/sushi-tailwind-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingState } from '../Landing.context';
import { finalize } from 'rxjs';
import { DiscountConditionInfoResponse } from '@model/response/discount-condition-summary.response';
import { dateToFormat } from '@helpers/utils/date-to-format';
import dayjs from 'dayjs';
import { DiscountConditionSummaryService } from '@core/services/discount-condition-summary.service';
import ModalDiscountConditionSummary from '@components/modal-discount-condition-summary/ModalDiscountConditionSummary';
import getYearString from '@helpers/utils/get-year-string';
import { Locale } from '@model/local.types';

const DiscountConditionSummary = (): React.ReactElement => {
  const { i18n, t } = useTranslation(['translation']);
  const [loading, loadingToggle] = useTrueFalse();
  const [visible, visibleToggle] = useTrueFalse();
  const [data, setData] = useState<DiscountConditionInfoResponse>();
  const { currentYear } = useLandingState();

  useEffect(() => {
    if (!currentYear) return;
    loadingToggle();
    DiscountConditionSummaryService.getDiscountConditionInfo(currentYear)
      .pipe(finalize(() => loadingToggle()))
      .subscribe({ next: (response) => setData(response) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const format = useMemo(() => {
    dayjs.locale(i18n.language);
    return `DD MMM ${i18n.language === 'th' ? 'BBBB' : 'YYYY'}`;
  }, [i18n.language]);

  return !data?.isHide ? (
    <Card
      title={data ? t('label:landing.discount_condition', { asOfYear: getYearString(data.asOfYear, i18n.language as Locale) }) : ''}
      loading={!currentYear}
      data-testid="discount-condition-summary-card"
    >
      <CardLoading loading={loading}>
        {data && (
          <div className="flex flex-col gap-2">
            <div>
              <span>{t('label:landing.discount_condition_10_percent')}</span>
              <div>{t('label:landing.discount_condition_1', { percentage: data.percentage })}</div>
              <div>
                {t('label:landing.discount_condition_2', {
                  date: data.finalDiscountDate ? dateToFormat(new Date(data.finalDiscountDate), format) : '-',
                })}
              </div>
            </div>
            <div>
              <span className="text-xs font-bold">{`${t('label:landing.remark')}: `}</span>
              <span className="text-xs">{t('label:landing.remark_discount_condition')}</span>
            </div>
            {data.isHideButton === false && (
              <div className="mt-1 text-center">
                <Button color="secondary" className="w-fit" onClick={visibleToggle} data-testid="discount-condition-summary-button">
                  {t('label:landing.discount_condition_summary_button')}
                </Button>
              </div>
            )}
            <ModalDiscountConditionSummary visible={visible} onCancel={visibleToggle} asOfYear={currentYear} />
          </div>
        )}
      </CardLoading>
    </Card>
  ) : (
    <></>
  );
};

export default DiscountConditionSummary;

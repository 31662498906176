/** @jsxImportSource @emotion/react */
import { Icon, Input, Menu, MenuItemProps, MenuItemSelect, StatusIcon } from '@mis/sushi-tailwind-react';
import React, { useCallback, useRef, useState } from 'react';
import { Link, useMatch, useMatches, useNavigate } from 'react-location';
import tw from 'twin.macro';
import classNames from 'classnames';
import { reportNodes, ReportNodeType, SubNodeType } from '@helpers/utils/report-node';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import nodeStatusToStatusIconType from '@helpers/utils/node-status-to-status-icon-type';
import { useReportDispatch, useReportState } from '@context/Report.context';
import { ReportDetail, ReportDetailStatus } from '@model/report-detail';
import { ReportMainNodeName, ReportNodeStatusType } from '@model/report-node';
import { useProfileState } from '@context/Profile.context';
import { UserRole } from '@model/enum/user-role.enum';
import { Field, Form, Formik } from 'formik';
import SearchChange from './SeachChange';
import { NodeDictionaryService } from '@core/services/node-dictionary.service';
import { Report } from '@model/Report';
import { useEffect } from 'react';
import CurrentUserReport from '@model/current-user-report';
import SiderLabel from './SiderLabel';
import useWebsocket from '@helpers/hooks/useWebsocket';
import { StompSubscription } from '@stomp/stompjs';

interface SiderLayoutProps {
  collapsed?: boolean;
  onWsConnected: (connected: boolean) => void;
}

const getStatusFromReportDetail = (nodeName: ReportMainNodeName, reportDetail: ReportDetail | null): ReportNodeStatusType | null => {
  if (reportDetail && reportDetail[nodeName as keyof Pick<ReportDetail, 'approveReport' | 'sendToApprover'>]) {
    if (['approveReport', 'sendToApprover', 'reportScope'].includes(nodeName)) {
      return reportDetail && reportDetail[nodeName as keyof Pick<ReportDetail, 'approveReport' | 'sendToApprover'>];
    }
    return reportDetail && reportDetail[nodeName as keyof Omit<ReportDetail, 'approveReport' | 'sendToApprover' | 'reportScope'>].status;
  }
  return null;
};

const getMainNodeStatus = (nodeName: ReportMainNodeName, reportDetail: ReportDetail | null) => {
  const status = getStatusFromReportDetail(nodeName, reportDetail);
  const disabled = status === 'INACTIVE';
  const type = disabled ? undefined : status ? nodeStatusToStatusIconType(status as ReportNodeStatusType) : undefined;
  return { disabled, type };
};

const SiderLayout = ({ collapsed, onWsConnected }: SiderLayoutProps): React.ReactElement => {
  const { reportDetail, currentUserReports, reportId } = useReportState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMatches();
  const { profile } = useProfileState();
  const [keyOnSelect, setKeyOnSelect] = useState('');
  const [reportNode, setReportNode] = useState<ReportNodeType>(reportNodes());
  const { data } = useMatch();
  const to = useMemo(() => {
    const report = data.report as Report;
    if (report && report.asOfYear) {
      return `/?year=${report.asOfYear}`;
    }
    return '/';
  }, [data.report]);
  const dispatch = useReportDispatch();
  const subRef = useRef<StompSubscription>();
  const { connect, disconnect, subscribe, unsubscribe, getHeaders } = useWebsocket();

  const handlerSubscribe = useCallback((reportId: number | string) => {
    try {
      subRef.current = subscribe(`/current-user/report/${reportId}`, (response) => {
        const body: CurrentUserReport[] = JSON.parse(response.body) || [];
        const currents = body ?? [];
        dispatch({ type: 'SET_CURRENT_USER_REPORTS', payload: currents });
        dispatch({ type: 'LOAD_REPORT_DETAIL' });
      });
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        handlerSubscribe(reportId);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerUnsubscribe = useCallback(() => {
    if (subRef.current) {
      try {
        unsubscribe(subRef.current.id);
      } catch (error) {
        console.error(error);
        const headers = getHeaders();
        subRef.current.unsubscribe(headers);
      }
      subRef.current = undefined;
    }
    if (disconnect) {
      disconnect();
    }
  }, [disconnect, getHeaders, unsubscribe]);

  useEffect(() => {
    if (reportId) {
      connect(() => {
        onWsConnected(true);
        handlerSubscribe(reportId);
      });
    }
    window.onbeforeunload = (e) => {
      e.preventDefault();
      handlerUnsubscribe();
      // return '';
    };
    return () => {
      onWsConnected(false);
      handlerUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const items = useMemo<MenuItemProps[]>(() => {
    const filterReportNodes = () => {
      const roles = profile?.roles.map((role) => role.roleName) || [];
      if (roles.includes(UserRole.ESG_CREATOR)) {
        return reportNode.filter((n) => n.nodeName !== 'approveReport');
      } else if (roles.includes(UserRole.ESG_APPROVER)) {
        return reportNode.filter((n) => n.nodeName !== 'sendToApprover');
      } else {
        return reportNode.filter((n) => n.nodeName !== 'sendToApprover' && n.nodeName !== 'approveReport');
      }
    };

    return filterReportNodes().map(({ nodeName, subNodes, no }) => {
      const rd = (reportDetail && reportDetail[nodeName]) as SubNodeType;
      const children =
        Array.isArray(subNodes) && subNodes.length > 0
          ? subNodes.map((subNode) => {
              const subNodeName = subNode.nodeName;
              const status = rd ? (rd[subNodeName] as ReportDetailStatus) : null;
              const type = status ? nodeStatusToStatusIconType(status.status) : undefined;
              const hasCurrentUser = currentUserReports.some(
                (currentUserReport) =>
                  currentUserReport.nodeName === subNodeName &&
                  currentUserReport.userProfile !== null &&
                  currentUserReport.userProfile.id !== null &&
                  currentUserReport.userProfile.id !== profile?.id
              );
              return {
                label: <SiderLabel label={t(`reportNode.${subNode.nodeName}.title`)} hasCurrentUser={hasCurrentUser} />,
                key: subNode.nodeName,
                itemIcon: (
                  <div className="flex justify-center items-center w-[22px] h-[22px]">
                    <StatusIcon size="sm" type={type} />
                  </div>
                ),
              };
            })
          : null;
      const { disabled, type } = getMainNodeStatus(nodeName, reportDetail);
      const hasCurrentUser = currentUserReports.some(
        (currentUserReport) =>
          currentUserReport.nodeName === nodeName &&
          currentUserReport.userProfile !== null &&
          currentUserReport.userProfile.id !== null &&
          currentUserReport.userProfile.id !== profile?.id
      );

      return {
        label: <SiderLabel label={t(`reportNode.${nodeName}.title`)} hasCurrentUser={hasCurrentUser} />,
        key: nodeName,
        itemIcon: (
          <div className="flex justify-center items-center w-[22px] h-[22px]">
            <StatusIcon type={type}>{no}</StatusIcon>
          </div>
        ),
        children,
        disabled: disabled,
      };
    }) as MenuItemProps[];
  }, [profile, reportDetail, t, reportNode, currentUserReports]);

  const currentNode = useMemo(() => {
    const children = matches.find((m) => m.route.id !== '/report/:reportId');
    if (children && children.route.path) {
      const key = children.route.path.replace('/', '');
      if (keyOnSelect !== '' && keyOnSelect !== key) {
        return keyOnSelect;
      }
      setKeyOnSelect('');
      return key;
    }
    return 'reportScope';
  }, [keyOnSelect, matches]);

  useEffect(() => {
    dispatch({ type: 'SET_CURRENT_NODE', payload: currentNode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNode]);

  const onSelectMenu = (item: MenuItemSelect) => {
    setKeyOnSelect(item.key);
    navigate({ to: `./${item.key}`, replace: true });
  };

  const onSearch = (val: { query: string }) => {
    if (!val.query) {
      setReportNode(reportNodes());
      return;
    }
    NodeDictionaryService.searchNodeDictionary(val.query).subscribe({
      next: (res) => {
        const reportNode = reportNodes();
        const result: AnyValue = reportNode
          .map((node) => {
            if (node.subNodes.length === 0) {
              return res.includes(node.nodeName) ? node : null;
            }
            if (Array.isArray(node.subNodes) && node.subNodes.length > 0) {
              const subNodes = (node.subNodes as { nodeName: SubNodeType }[]).filter((sub) => res.includes(sub.nodeName));
              if (subNodes.length > 0) {
                return { ...node, subNodes };
              }
            }
          })
          .filter((r: AnyValue) => r !== null && r !== undefined);
        setReportNode(result);
      },
    });
  };

  return (
    <div>
      <div className={classNames('grid grid-cols-7 gap-4 p-4 pb-0', collapsed ? 'hidden' : undefined)}>
        <Link to={to} className="flex items-center" data-testid="go-back">
          <Icon name="chevron_left" />
        </Link>
        <div css={tw`col-span-6`}>
          <Formik initialValues={{ query: '' }} onSubmit={onSearch}>
            {() => (
              <Form>
                <label className="block relative w-full">
                  <Field type={'text'} name="query" className="m-0">
                    {({ field }: AnyValue) => (
                      <div>
                        <Input {...field} autoComplete="off" suffix={<Icon size="text-lg" name="search" />} />
                      </div>
                    )}
                  </Field>
                </label>
                <SearchChange debounceMs={1000} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="overflow-auto pt-1 pb-[100px] h-full">
        <div className="overflow-auto px-4 h-full bg-fixed">
          <Menu
            collapsed={collapsed}
            className="text-black"
            defaultOpenKeys={['environment', 'social', 'governance']}
            items={items}
            onSelect={onSelectMenu}
            selectedKeys={[currentNode]}
            parentItemBold
          />
        </div>
      </div>
    </div>
  );
};

export default SiderLayout;

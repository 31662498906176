import { useYearOptions } from '@helpers/hooks/useYearOptions';
import getYearString from '@helpers/utils/get-year-string';
import { Dropdown, DropdownOption } from '@mis/sushi-tailwind-react';
import React, { useMemo } from 'react';
import { useLandingDispatch, useLandingState } from '../Landing.context';
import useServerCurrentDateTime from '@helpers/hooks/useServerCurrentTime';

const DropdownYear = (): JSX.Element => {
  const { currentYear, currentDateTime, defaultYear } = useLandingState();
  const dispatch = useLandingDispatch();
  const [cDateTime] = useServerCurrentDateTime();
  const years = useYearOptions(cDateTime.firstYear, currentDateTime, defaultYear);
  const options = useMemo<DropdownOption[]>(() => {
    if (Array.isArray(years)) {
      return years.map((year) => ({ label: getYearString(year), value: year }));
    }
    return [];
  }, [years]);
  const value = useMemo(() => {
    if (currentYear) {
      if (!options.some((option) => option.value === currentYear)) {
        return options[0].value;
      }
    }
    return currentYear;
  }, [currentYear, options]);

  const onDropdownChange = (value: number) => {
    dispatch({ type: 'SET_CURRENT_YEAR', payload: value });
  };

  return (
    <div className="md:w-[180px]">
      <Dropdown onChange={onDropdownChange} defaultValue={currentYear} value={value} options={options} data-testid={'year-dropdown'} />
    </div>
  );
};

export default DropdownYear;

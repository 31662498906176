/** @jsxImportSource @emotion/react */
import { Menu, MenuItemProps, MenuItemSelect } from '@mis/sushi-tailwind-react';
import React, { useState } from 'react';
import { useMatches, useNavigate } from 'react-location';
import classNames from 'classnames';
import { useMemo } from 'react';
import SiderLabel from './SiderLabel';
import { useTranslation } from 'react-i18next';

interface BackOfficeSiderLayoutProps {
  collapsed?: boolean;
}

const BackOfficeSiderLayout = ({ collapsed }: BackOfficeSiderLayoutProps): React.ReactElement => {
  const navigate = useNavigate();
  const [keyOnSelect, setKeyOnSelect] = useState('');
  const matches = useMatches();
  const { t } = useTranslation(['translation']);

  const items = useMemo<MenuItemProps[]>(() => {
    const menus = [
      { label: <SiderLabel label={t('backOffice.monitoring_report.title')} />, key: 'monitoringReport' },
      { label: <SiderLabel label={t('backOffice.discount_condition_report.title')} />, key: 'discountConditionReport' },
    ];
    return menus.map((m: MenuItemProps) => {
      if (m.children) {
        return { ...m, itemIcon: <></>, children: (m.children || []).map((c) => ({ ...c, itemIcon: <></> })) };
      } else return { ...m, itemIcon: <></> };
    });
  }, [t]);

  const currentNode = useMemo(() => {
    const children = matches.find((m) => m.route.id !== '/backOffice');
    if (children?.route.path) {
      const key = children.route.path.replace('/', '');
      if (keyOnSelect !== '' && keyOnSelect !== key) {
        return keyOnSelect;
      }
      setKeyOnSelect('');
      return key;
    }
    return 'monitoringReport';
  }, [keyOnSelect, matches]);

  const onSelectMenu = (item: MenuItemSelect) => {
    setKeyOnSelect(item.key);
    navigate({ to: `./${item.key}`, replace: true });
  };

  return (
    <div>
      <div className={classNames('grid grid-cols-7 gap-4 p-4 pb-0', collapsed ? 'hidden' : undefined)}></div>
      <div className="overflow-auto pt-1 pb-[100px] h-full">
        <div className="overflow-auto px-4 h-full bg-fixed">
          <Menu
            collapsed={collapsed}
            className="text-black"
            defaultOpenKeys={['monitoringReport']}
            items={items}
            onSelect={onSelectMenu}
            selectedKeys={[currentNode]}
          />
        </div>
      </div>
    </div>
  );
};

export default BackOfficeSiderLayout;

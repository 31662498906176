import React from 'react';
export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  fill?: string;
  className?: string;
}

const Information = ({ fill, className, ...props }: IconProps): JSX.Element => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" className={className} fill="inherit" {...props}>
      <path
        d="M2.00732 0.5C1.18843 0.5 0.507324 1.17874 0.507324 1.99854L0.5 15.5L3.5 12.5H14C14.8198 12.5 15.5 11.8198 15.5 11V2C15.5 1.1802 14.8198 0.5 14 0.5H2.00732ZM2.00732 2H14V11H2.87891L2.00293 11.876L2.00732 2ZM7.25 3.5V5H8.75V3.5H7.25ZM7.25 6.5V9.5H8.75V6.5H7.25Z"
        fill={fill || 'inherit'}
      />
    </svg>
  );
};

const OneReport = ({ fill, className, ...props }: IconProps): JSX.Element => {
  // return (
  //   <svg width="100%" height="100%" viewBox="0 0 14 14" className={className} fill="inherit" {...props}>
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M0.333374 2.33349C0.333374 1.22892 1.2288 0.333496 2.33337 0.333496H11.6667C12.7713 0.333496 13.6667 1.22893 13.6667 2.3335V4.3335H12.3334V2.66683C12.3334 2.11454 11.8857 1.66683 11.3334 1.66683H2.66671C2.11442 1.66683 1.66671 2.11455 1.66671 2.66683V11.3335C1.66671 11.8858 2.11442 12.3335 2.66671 12.3335H7.66671V13.6668H2.33337C1.2288 13.6668 0.333374 12.7714 0.333374 11.6668V2.33349ZM3.00004 3.00016H9.66671V4.3335H3.00004V3.00016ZM7.00004 5.66683H3.00004V7.00016H7.00004V5.66683ZM3.00004 8.3335H7.00004V9.66683H3.00004V8.3335ZM10.4734 12.3875H9.19271V13.6002H13.3747V12.3875H12.1054V5.66683H11.04L9.00004 6.9135V8.36416L10.4734 7.4915V12.3875Z"
  //       fill={fill || 'inherit'}
  //     />
  //   </svg>
  // );
  return (
    <svg width="100%" height="100%" viewBox="0 0 12 14" fill="none" className={className} {...props}>
      <path
        d="M7.6113 0.565275C7.43159 0.415512 7.20506 0.333496 6.97112 0.333496H1.99984C1.2665 0.333496 0.666504 0.933496 0.666504 1.66683V12.3335C0.666504 13.0668 1.25984 13.6668 1.99317 13.6668H9.99984C10.7332 13.6668 11.3332 13.0668 11.3332 12.3335V4.13521C11.3332 3.83843 11.2013 3.55698 10.9734 3.36698L7.6113 0.565275ZM10.6665 12.0002C10.6665 12.5524 10.2185 13.0002 9.66619 13.0002C7.07944 13.0002 4.88633 13.0002 2.33349 13.0002C1.78121 13.0002 1.33317 12.5524 1.33317 12.0002V7.00016C1.33317 5.01165 1.33317 3.76454 1.33317 2.00045C1.33317 1.44816 1.78089 1.00016 2.33317 1.00016H6.33317C6.88545 1.00016 7.33317 1.44788 7.33317 2.00016V2.66683C7.33317 3.21911 7.78089 3.66683 8.33317 3.66683H9.6665C10.2188 3.66683 10.6665 4.11454 10.6665 4.66683V12.0002Z"
        fill={fill || 'inherit'}
      />
      <path d="M11.3335 3.66683H7.3335V0.333496L11.3335 3.66683Z" fill={fill || 'inherit'} />
      <path
        d="M2.94849 8.00423C2.67033 8.00423 2.4311 7.94442 2.23082 7.82481C2.03054 7.7052 1.87754 7.53273 1.77184 7.30742C1.66613 7.0821 1.61328 6.81366 1.61328 6.50211C1.61328 6.19056 1.66613 5.92213 1.77184 5.69681C1.87754 5.4715 2.03054 5.29903 2.23082 5.17942C2.4311 5.05981 2.67033 5 2.94849 5C3.22666 5 3.46589 5.05981 3.66617 5.17942C3.86645 5.29903 4.01945 5.4715 4.12515 5.69681C4.23086 5.92213 4.28371 6.19056 4.28371 6.50211C4.28371 6.81366 4.23086 7.0821 4.12515 7.30742C4.01945 7.53273 3.86645 7.7052 3.66617 7.82481C3.46589 7.94442 3.22666 8.00423 2.94849 8.00423ZM2.94849 7.47432C3.15434 7.47432 3.31568 7.38808 3.43251 7.21562C3.55212 7.04315 3.61193 6.80532 3.61193 6.50211C3.61193 6.19891 3.55212 5.96108 3.43251 5.78861C3.31568 5.61615 3.15434 5.52991 2.94849 5.52991C2.74265 5.52991 2.57992 5.61615 2.46031 5.78861C2.34348 5.96108 2.28506 6.19891 2.28506 6.50211C2.28506 6.80532 2.34348 7.04315 2.46031 7.21562C2.57992 7.38808 2.74265 7.47432 2.94849 7.47432Z"
        fill="#1F1F1F"
      />
      <path
        d="M7.08012 5.04173V7.9625H6.47093L5.34435 6.17248V7.9625H4.67674V5.04173H5.31514L6.41252 6.79837V5.04173H7.08012Z"
        fill="#1F1F1F"
      />
      <path
        d="M7.61463 5.04173H9.61328V5.57581H8.28641V6.16414H9.47141V6.69822H8.28641V7.42842H9.61328V7.9625H7.61463V5.04173Z"
        fill="#1F1F1F"
      />
      <path
        d="M2 8.95996H2.67086C3.09026 8.95996 3.29996 9.13225 3.29996 9.47681C3.29996 9.59515 3.2756 9.69695 3.22687 9.78223C3.17989 9.8675 3.11115 9.93102 3.02065 9.97278V9.978C3.05198 10.0006 3.07634 10.0224 3.09374 10.0433C3.11115 10.0641 3.12507 10.0868 3.13551 10.1111L3.40177 10.7872H2.958L2.7544 10.2417C2.73525 10.1929 2.71002 10.1607 2.6787 10.1451C2.64911 10.1294 2.59516 10.1216 2.51685 10.1216H2.42027V10.7872H2V8.95996ZM2.62388 9.79006C2.70915 9.79006 2.77441 9.7683 2.81966 9.7248C2.86664 9.67955 2.89014 9.61516 2.89014 9.53163C2.89014 9.37327 2.79964 9.29409 2.61866 9.29409H2.42027V9.79006H2.62388Z"
        fill="#1F1F1F"
      />
      <path
        d="M4.75808 10.2704H3.86011C3.87577 10.3487 3.90797 10.407 3.95669 10.4453C4.00716 10.4835 4.07503 10.5027 4.1603 10.5027C4.23513 10.5027 4.30039 10.4905 4.35608 10.4661C4.41177 10.4418 4.46746 10.4 4.52314 10.3408L4.72153 10.5288C4.67106 10.6141 4.59188 10.6828 4.48399 10.735C4.37783 10.7872 4.26211 10.8133 4.13681 10.8133C3.93146 10.8133 3.77049 10.7542 3.65389 10.6358C3.5373 10.5157 3.479 10.3504 3.479 10.1398C3.479 9.92754 3.5373 9.76047 3.65389 9.63866C3.77049 9.51684 3.93059 9.45593 4.1342 9.45593C4.32911 9.45593 4.48138 9.51684 4.59101 9.63866C4.70239 9.75873 4.75808 9.92493 4.75808 10.1372V10.2704ZM4.39263 10.0067C4.39263 9.93015 4.36913 9.87011 4.32215 9.8266C4.27516 9.78136 4.21077 9.75873 4.12898 9.75873C4.05589 9.75873 3.99585 9.78223 3.94886 9.82921C3.90188 9.87446 3.87055 9.93885 3.85489 10.0224H4.39263V10.0067Z"
        fill="#1F1F1F"
      />
      <path
        d="M4.9664 9.83443C4.9664 9.77353 4.96466 9.71088 4.96118 9.64649C4.95944 9.5821 4.95683 9.52728 4.95334 9.48203H5.30574C5.31096 9.53076 5.31358 9.58471 5.31358 9.64388H5.3188C5.36404 9.58297 5.41973 9.53685 5.48586 9.50553C5.55199 9.47246 5.62769 9.45593 5.71296 9.45593C5.87828 9.45593 6.0088 9.51684 6.10452 9.63866C6.20023 9.76047 6.24809 9.92667 6.24809 10.1372C6.24809 10.3495 6.19936 10.5157 6.10191 10.6358C6.00619 10.7542 5.87306 10.8133 5.70252 10.8133C5.62247 10.8133 5.55025 10.8003 5.48586 10.7742C5.42321 10.7481 5.37622 10.7124 5.3449 10.6671H5.33707V11.4189H4.9664V9.83443ZM5.61638 10.5392C5.70165 10.5392 5.76604 10.5044 5.80954 10.4348C5.85479 10.3635 5.87741 10.2608 5.87741 10.1268C5.87741 9.8675 5.79214 9.73785 5.6216 9.73785C5.53633 9.73785 5.46759 9.76221 5.41538 9.81094C5.36317 9.85967 5.33707 9.92319 5.33707 10.0015V10.2808C5.33707 10.3522 5.36404 10.4131 5.41799 10.4635C5.47368 10.514 5.53981 10.5392 5.61638 10.5392Z"
        fill="#1F1F1F"
      />
      <path
        d="M7.09199 10.8133C6.8762 10.8133 6.7074 10.7533 6.58558 10.6332C6.4655 10.5131 6.40546 10.3469 6.40546 10.1346C6.40546 9.92232 6.4655 9.75612 6.58558 9.63605C6.7074 9.51597 6.8762 9.45593 7.09199 9.45593C7.30778 9.45593 7.47571 9.51597 7.59579 9.63605C7.71761 9.75612 7.77852 9.92232 7.77852 10.1346C7.77852 10.3469 7.71761 10.5131 7.59579 10.6332C7.47571 10.7533 7.30778 10.8133 7.09199 10.8133ZM7.09199 10.5392C7.19118 10.5392 7.26862 10.5036 7.32431 10.4322C7.38 10.3609 7.40784 10.2617 7.40784 10.1346C7.40784 10.0076 7.38 9.90839 7.32431 9.83704C7.26862 9.76569 7.19118 9.73002 7.09199 9.73002C6.9928 9.73002 6.91535 9.76569 6.85967 9.83704C6.80398 9.90839 6.77613 10.0076 6.77613 10.1346C6.77613 10.2617 6.80398 10.3609 6.85967 10.4322C6.91535 10.5036 6.9928 10.5392 7.09199 10.5392Z"
        fill="#1F1F1F"
      />
      <path
        d="M7.98718 9.8005C7.98718 9.65258 7.98457 9.54642 7.97935 9.48203H8.33175C8.33871 9.5499 8.34219 9.62647 8.34219 9.71175V9.73785H8.35263C8.3857 9.65258 8.44487 9.58471 8.53014 9.53424C8.61715 9.48203 8.71634 9.45593 8.82772 9.45593C8.85034 9.45593 8.87732 9.45854 8.90864 9.46376V9.75351C8.90516 9.75177 8.88776 9.7509 8.85643 9.7509C8.76768 9.7509 8.68502 9.77353 8.60845 9.81877C8.53188 9.86402 8.47097 9.92406 8.42572 9.99889C8.38048 10.0737 8.35785 10.1546 8.35785 10.2417V10.7872H7.98718V9.8005Z"
        fill="#1F1F1F"
      />
      <path
        d="M9.65021 10.8133C9.50925 10.8133 9.39961 10.7768 9.3213 10.7037C9.24473 10.6306 9.20645 10.5279 9.20645 10.3957V9.75612H8.9689V9.48203H9.20645V9.15052L9.56668 9.0461H9.57712V9.48203H9.95823V9.75612H9.57712V10.3382C9.57712 10.4078 9.59452 10.4592 9.62933 10.4922C9.66413 10.5236 9.71808 10.5392 9.79117 10.5392C9.86078 10.5392 9.92517 10.5279 9.98434 10.5053L10 10.7559C9.91299 10.7942 9.79639 10.8133 9.65021 10.8133Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};

const Download = ({ fill, className, ...props }: IconProps): JSX.Element => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 17" className={className} fill="inherit" {...props}>
      <path
        d="M19.35 6.37325C18.67 2.92325 15.64 0.333252 12 0.333252C9.11 0.333252 6.6 1.97325 5.35 4.37325C2.34 4.69325 0 7.24325 0 10.3333C0 13.6433 2.69 16.3333 6 16.3333H19C21.76 16.3333 24 14.0933 24 11.3333C24 8.69325 21.95 6.55325 19.35 6.37325ZM19 14.3333H6C3.79 14.3333 2 12.5433 2 10.3333C2 8.28325 3.53 6.57325 5.56 6.36325L6.63 6.25325L7.13 5.30325C8.08 3.47325 9.94 2.33325 12 2.33325C14.62 2.33325 16.88 4.19325 17.39 6.76325L17.69 8.26325L19.22 8.37325C20.78 8.47325 22 9.78325 22 11.3333C22 12.9833 20.65 14.3333 19 14.3333ZM13.45 6.33325H10.55V9.33325H8L12 13.3333L16 9.33325H13.45V6.33325Z"
        fill={fill || 'inherit'}
      />
    </svg>
  );
};

OneReport.defaultProps = {
  fill: '#212121',
};

const Alert = ({ fill, className, ...props }: IconProps) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 27" className={className} fill="none" {...props}>
      <path
        d="M10.6667 18.9997H13.3333V21.6663H10.6667V18.9997ZM10.6667 8.33301H13.3333V16.333H10.6667V8.33301ZM21.3333 2.99967H15.76C15.2 1.45301 13.7333 0.333008 12 0.333008C10.2667 0.333008 8.8 1.45301 8.24 2.99967H2.66667C2.48 2.99967 2.30667 3.01301 2.13333 3.05301C1.61333 3.15967 1.14667 3.42634 0.786667 3.78634C0.546667 4.02634 0.346667 4.31967 0.213333 4.63967C0.0800001 4.94634 0 5.29301 0 5.66634V24.333C0 24.693 0.0800001 25.053 0.213333 25.373C0.346667 25.693 0.546667 25.973 0.786667 26.2263C1.14667 26.5863 1.61333 26.853 2.13333 26.9597C2.30667 26.9863 2.48 26.9997 2.66667 26.9997H21.3333C22.8 26.9997 24 25.7997 24 24.333V5.66634C24 4.19967 22.8 2.99967 21.3333 2.99967ZM12 2.66634C12.5467 2.66634 13 3.11967 13 3.66634C13 4.21301 12.5467 4.66634 12 4.66634C11.4533 4.66634 11 4.21301 11 3.66634C11 3.11967 11.4533 2.66634 12 2.66634ZM21.3333 24.333H2.66667V5.66634H21.3333V24.333Z"
        fill={fill}
      />
    </svg>
  );
};

Alert.defaultProps = {
  fill: '#F68809',
};

const SyncDataFromSystem = ({ fill, className, ...props }: IconProps) => {
  return (
    <svg width={props.width || '1em'} height={props.hanging || '1em'} viewBox="0 0 12 16" className={className} fill="none" {...props}>
      <path
        d="M5.99984 4.00033V6.00033L8.6665 3.33366L5.99984 0.666992V2.66699C3.05317 2.66699 0.666504 5.05366 0.666504 8.00033C0.666504 9.04699 0.97317 10.0203 1.49317 10.8403L2.4665 9.86699C2.1665 9.31366 1.99984 8.67366 1.99984 8.00033C1.99984 5.79366 3.79317 4.00033 5.99984 4.00033ZM10.5065 5.16033L9.53317 6.13366C9.8265 6.69366 9.99984 7.32699 9.99984 8.00033C9.99984 10.207 8.2065 12.0003 5.99984 12.0003V10.0003L3.33317 12.667L5.99984 15.3337V13.3337C8.9465 13.3337 11.3332 10.947 11.3332 8.00033C11.3332 6.95366 11.0265 5.98033 10.5065 5.16033Z"
        fill={fill}
      />
    </svg>
  );
};

SyncDataFromSystem.defaultProps = {
  fill: '#212121',
};

const ExternalLink = ({ fill, className, ...props }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className={className} fill="none" {...props}>
      <path
        d="M12.6667 12.6667H3.33333V3.33333H8V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V8H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L5.17333 9.88667L6.11333 10.8267L12.6667 4.27333V6.66667H14V2H9.33333Z"
        fill={fill}
      />
    </svg>
  );
};

ExternalLink.defaultProps = {
  fill: '#0064C5',
};

const Remark = ({ fill, className, ...props }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className={className} fill="none" {...props}>
      <rect x="0.05" y="0.05" width="15.9" height="15.9" rx="3.95" fill="url(#paint0_linear_12527_198542)" />
      <path
        d="M7.94861 13.3337V12.3081L11.1178 9.1881L12.1595 10.2137L8.99033 13.3337H7.94861ZM2.66663 10.3003V9.43366H7.06828V10.3003H2.66663ZM12.7904 9.59255L11.7487 8.56699L12.1742 8.1481C12.2524 8.07107 12.3551 8.03255 12.4823 8.03255C12.6095 8.03255 12.7122 8.07107 12.7904 8.1481L13.2159 8.56699C13.2942 8.64403 13.3333 8.74514 13.3333 8.87033C13.3333 8.99551 13.2942 9.09662 13.2159 9.17366L12.7904 9.59255ZM2.66663 7.91699V7.05033H9.56254V7.91699H2.66663ZM2.66663 5.53366V4.66699H9.56254V5.53366H2.66663Z"
        fill={fill}
      />
      <rect x="0.05" y="0.05" width="15.9" height="15.9" rx="3.95" stroke="#5F6062" strokeWidth="0.1" />
      <defs>
        <linearGradient id="paint0_linear_12527_198542" x1="8" y1="0" x2="8" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FAFAFA" />
          <stop offset="1" stopColor="#F5F5F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Remark.defaultProps = {
  fill: 'black',
};

interface IconInterface {
  Information: typeof Information;
  OneReport: typeof OneReport;
  Alert: typeof Alert;
  Download: typeof Download;
  SyncDataFromSystem: typeof SyncDataFromSystem;
  ExternalLink: typeof ExternalLink;
  Remark: typeof Remark;
}

export default { Information, OneReport, Alert, Download, SyncDataFromSystem, ExternalLink, Remark } as IconInterface;

import React, { useEffect, useState } from 'react';
import { env } from '@core/env';
import ModalConfirm from '@components/modal-confirm/ModalConfirm';
import useTrueFalse from '@helpers/hooks/useTrueFalse';

export default function AnotherUserLoginPopup(): React.ReactElement | null {
  const [secondsToGo, setSecondsToGo] = useState<number>(20);
  const [visible, onVisibleTrigger] = useTrueFalse();

  useEffect(() => {
    onVisibleTrigger();
    const timer = setInterval(() => {
      setSecondsToGo((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      window.location.href = env.setLinkURL;
    }, 20000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGoToSETLink = () => {
    window.location.href = env.setLinkURL;
  };

  return (
    <ModalConfirm
      visible={visible}
      onOk={onGoToSETLink}
      title={<span className="font-bold">มีคนเข้าสู่ระบบ ESG</span>}
      width={380}
      hideCancel
      closable={false}
    >
      <div>
        <span>มีคนเข้าสู่ระบบ ESG ด้วย User ที่คุณกำลังใช้อยู่</span>
        <span>
          เราจะพาคุณกลับไป{' '}
          <a className="text-[#00A7CC]" href={env.setLinkURL}>
            {env.setLinkURL}
          </a>{' '}
          ใน {secondsToGo} วินาที
        </span>
      </div>
    </ModalConfirm>
  );
}

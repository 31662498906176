import { ConfigService } from '@core/services/config.service';
import { ReportService } from '@core/services/report.service';
import CurrentUserReport from '@model/current-user-report';
import { ReportDetail } from '@model/report-detail';
import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { debounce, forkJoin, Subject, switchMap, timer } from 'rxjs';

type Action =
  | { type: 'SET_REPORT_DETAIL'; payload: ReportDetail }
  | { type: 'LOAD_REPORT_DETAIL' }
  | { type: 'SET_CURRENT_NODE'; payload: string | null }
  | { type: 'SET_CURRENT_USER_REPORTS'; payload: CurrentUserReport[] }
  | { type: 'SET_ATTACH_SIZE'; payload: number };
type Dispatch = (action: Action) => void;
type State = {
  reportId: number | string;
  reportDetail: ReportDetail | null;
  loadReportDetail: boolean;
  attachSize: number;
  currentNode: string | null;
  currentUserReports: CurrentUserReport[];
};

const ReportStateContext = createContext<State | undefined>(undefined);
const ReportDispatchContext = createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_REPORT_DETAIL':
      return { ...state, reportDetail: action.payload, loadReportDetail: false };
    case 'LOAD_REPORT_DETAIL':
      return { ...state, loadReportDetail: true };
    case 'SET_ATTACH_SIZE':
      return { ...state, attachSize: action.payload };
    case 'SET_CURRENT_NODE': {
      return { ...state, currentNode: action.payload };
    }
    case 'SET_CURRENT_USER_REPORTS': {
      return { ...state, currentUserReports: action.payload };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface ReportProviderProps {
  reportId: number | string;
  children?: React.ReactNode;
}
const ReportProvider = ({ reportId, children }: ReportProviderProps): React.ReactElement => {
  const [state, dispatch] = useReducer(reducer, {
    reportId,
    reportDetail: null,
    loadReportDetail: false,
    attachSize: 0,
    currentNode: null,
    currentUserReports: [],
  });
  const loadReportDetailRef = useRef(new Subject<void>());

  useEffect(() => {
    dispatch({ type: 'LOAD_REPORT_DETAIL' });
    let count = 0;
    const sub$ = loadReportDetailRef.current
      .pipe(
        debounce(() => {
          if (count === 0) {
            count++;
            return timer(0);
          }
          return timer(1500);
        }),
        switchMap(() =>
          forkJoin({ reportDetail: ReportService.getReportDetail(reportId), config: ConfigService.getMaxFileSize({ type: 'attach' }) })
        )
      )
      .subscribe({
        next: ({ reportDetail, config }) => {
          dispatch({ type: 'SET_REPORT_DETAIL', payload: reportDetail || [] });
          dispatch({ type: 'SET_ATTACH_SIZE', payload: config.maxFileSize });
        },
      });
    return () => {
      if (sub$) {
        sub$.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.loadReportDetail) {
      return;
    }
    loadReportDetailRef.current.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loadReportDetail]);

  return (
    <ReportStateContext.Provider value={state}>
      <ReportDispatchContext.Provider value={dispatch}>{children}</ReportDispatchContext.Provider>
    </ReportStateContext.Provider>
  );
};

const useReportState = (): State => {
  const context = React.useContext(ReportStateContext);
  if (context === undefined) {
    throw new Error('useReportState must be used within a ReportProvider');
  }
  return context;
};
const useReportDispatch = (): Dispatch => {
  const context = React.useContext(ReportDispatchContext);
  if (context === undefined) {
    throw new Error('useReportDispatch must be used within a ReportProvider');
  }
  return context;
};

export { ReportProvider, useReportState, useReportDispatch };

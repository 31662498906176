import { useProfileState } from '@context/Profile.context';
import { UserRole } from '@model/enum/user-role.enum';
import React from 'react';

interface RoleGuardProps {
  children?: React.ReactNode;
}

export default function RoleGuard({ children }: RoleGuardProps): React.ReactElement | null {
  const { profile } = useProfileState();

  if (profile) {
    if (profile.roles.some((role) => role.roleName === UserRole.ESG_REG)) {
      if (!window.location.pathname.includes('/backOffice/')) {
        window.location.href = '/backOffice/monitoringReport';
      }
    } else {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  }
  return <>{children}</>;
}

import { Icon } from '@mis/sushi-tailwind-react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ExplanationLabelProps {
  label: React.ReactNode;
  ['data-testid']?: string;
  iconPosition?: 'start' | 'center' | 'end';
}

export default function ExplanationLabel({ label, iconPosition = 'center', ...props }: ExplanationLabelProps): React.ReactElement {
  const { t } = useTranslation(['title']);
  return (
    <p
      className={classNames('flex flex-row gap-1', { 'items-center': iconPosition === 'center', 'items-end': iconPosition === 'end' })}
      data-testid={props['data-testid'] || 'explanation-label'}
    >
      <Icon
        name="info"
        type="outlined"
        color="text-[#5F6062]"
        size="text-[13px]"
        className={classNames({ 'pt-1': iconPosition === 'start' })}
      />
      <span className="text-[#5F6062]">
        {t('title:other.statement')}&nbsp;:&nbsp;{label}
      </span>
    </p>
  );
}

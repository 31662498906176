/* eslint-disable no-extra-boolean-cast */
import HeaderLayout from '@layouts/header/HeaderLayout';
import React from 'react';
import { Layout, Sider, BreadcrumbRouteProps, Breadcrumb, Content } from '@mis/sushi-tailwind-react';
import { useMatches } from 'react-location';
import { useLayoutDispatch, useLayoutState } from '@context/Layout.context';
import { useMemo } from 'react';
import { RouteMeta } from '@model/route-meta';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import styled from '@emotion/styled';
import BackOfficeSiderLayout from '@layouts/sider/BackOfficeSiderLayout';
import classNames from 'classnames';
import { Outlet } from 'react-location';
import { useNavigate } from 'react-location';
import InitialData from '@components/initial-data/InitialData';

const SiderComponent = styled(Sider)`
  position: fixed;
`;

export const BackOfficeLayout = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useLayoutDispatch();
  const { collapsed } = useLayoutState();
  const matches = useMatches();

  const routes = useMemo<BreadcrumbRouteProps[]>(() => {
    const filterMatches = _.flatten(matches.filter((m) => m.route.meta).map((m) => (m.route.meta as unknown as RouteMeta)?.breadcrumb()));
    return filterMatches.map((m: BreadcrumbRouteProps) => ({
      label: t(m.label),
      href: m.href,
    }));
  }, [matches, t]);

  const onCollapse = (payload: boolean) => {
    dispatch({ type: 'COLLAPSED', payload });
  };

  const onClickBreadcrumb = (m: BreadcrumbRouteProps) => {
    navigate({ to: `/backOffice/${m.href}`, replace: true });
  };

  return (
    <InitialData>
      <Layout className="min-h-screen">
        <HeaderLayout />
        <Layout className="mt-12">
          <SiderComponent
            collapsible
            className="left-0 h-full bg-white duration-300"
            breakpoint="md"
            width={'16.75rem'}
            collapsedWidth="0"
            collapsed={collapsed}
            onCollapse={onCollapse}
          >
            <BackOfficeSiderLayout collapsed={collapsed} />
          </SiderComponent>

          <Content
            id="back-office-content"
            className={classNames('flex-col px-6 mb-10 md:px-10 transition-all duration-300', collapsed ? undefined : 'ml-67')}
          >
            <div className="relative w-full">
              <div className="container flex flex-col gap-4 px-3 pb-5 mx-auto max-w-[964px] md:px-2">
                <Breadcrumb routes={routes} className="hidden py-3 md:flex" onClick={onClickBreadcrumb} />
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </InitialData>
  );
};

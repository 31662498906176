import Icon from '@components/icon/Icon';
import { DiscountConditionSummaryService } from '@core/services/discount-condition-summary.service';
import { DownloadService } from '@core/services/download.service';
import { useNotification } from '@helpers/hooks/useNotification';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import { Card, Spin } from '@mis/sushi-tailwind-react';
import FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs';
import { useLandingState } from './Landing.context';

interface LisItemProps {
  filename: string;
  children?: React.ReactNode;
}

const LisItem = ({ children, filename }: LisItemProps) => {
  const [loading, loadingToggle] = useTrueFalse();
  const { t } = useTranslation(['translation']);
  const notification = useNotification();
  const onDownload = () => {
    if (loading) {
      return;
    }
    loadingToggle();
    DownloadService.downloadDocumentWithName(filename)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            loadingToggle();
          }, 1500);
        })
      )
      .subscribe({
        next: (response) => {
          const { data, headers } = response;
          const fileName = headers['content-disposition'].split('filename=')[1];
          FileSaver.saveAs(new Blob([data]), fileName);
        },
        error: () => notification.error({ message: t('translation:alert.download_failed') }),
      });
  };
  return (
    <li onClick={onDownload} className=" hover:opacity-50">
      <div className="inline-flex gap-[5px] items-center cursor-pointer">
        {children}
        {loading ? <Spin /> : <Icon.Download fill="#0064C5" width={24} />}
      </div>
    </li>
  );
};

const ESGInformation = (): React.ReactElement => {
  const { currentYear } = useLandingState();
  const [showDiscountChecklist, setShowDiscountChecklist] = useState<boolean>(false);

  useEffect(() => {
    if (currentYear == null) return;
    DiscountConditionSummaryService.getDiscountConditionInfo(currentYear).subscribe({
      next: (response) => setShowDiscountChecklist(!response.isHide),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  return (
    <Card title={<span>คำอธิบาย</span>}>
      <span>
        ระบบนี้พัฒนาขึ้นเพื่อเป็นช่องทางการเปิดเผยข้อมูลด้านสิ่งแวดล้อม สังคม และการกำกับดูแลกิจการ (Environmental, Social and Governance:
        ESG) ของบริษัทจดทะเบียน นอกจากนี้ ระบบดังกล่าวยังเป็นฐานข้อมูลด้าน ESG ของตลาดทุน
        ซึ่งเป็นประโยชน์ต่อบริษัทในการนำข้อมูลไปตัดสินใจทางธุรกิจและพัฒนาองค์กรอย่างต่อเนื่อง อีกทั้งเป็นช่องทางเผยแพร่ข้อมูลด้าน ESG
        แก่ผู้ลงทุนทั้งในและต่างประเทศ
      </span>
      <ul className="mt-3 list-disc" style={{ paddingInlineStart: 15 }}>
        <LisItem filename="ESG_Data_Platform_Manual.pdf">คู่มือการใช้งาน ESG Data Platform</LisItem>
        <LisItem filename="ESG_Data_Platform_Template.xlsx">เครื่องมือรวบรวมข้อมูล ESG Data Template (Excel Format)</LisItem>
        {showDiscountChecklist && (
          <LisItem filename="ESG_Data_Platform_Checklist_DiscountCondition.xlsx">
            Checklist การจัดทำข้อมูล ESG ตามมาตรการสนับสนุน ประจำปี 2566
          </LisItem>
        )}
      </ul>
    </Card>
  );
};

export default ESGInformation;

import CardLoading from '@components/card-loading/CardLoading';
import LinkToLDP from '@components/link-to-ldp/LinkToLdp';
import { ReportService } from '@core/services/report.service';
import useDateTimeFormat from '@helpers/hooks/useDateTimeFormat';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import { dateToFormat } from '@helpers/utils/date-to-format';
import getYearString from '@helpers/utils/get-year-string';
import { Card, StatusIcon, StatusIconType, Timeline } from '@mis/sushi-tailwind-react';
import { Locale } from '@model/local.types';
import { ReportStatusResponse, DisclosureStatusType } from '@model/response/report-status.response';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingState } from '../Landing.context';

const DisclosureStatus = (): React.ReactElement => {
  const { i18n, t } = useTranslation(['translation']);
  const { currentYear, currentReportId } = useLandingState();
  const [data, setData] = useState<ReportStatusResponse | undefined>();
  const [loading, loadingToggle] = useTrueFalse();
  const dateTimeFormat = useDateTimeFormat();

  const getData = (id: number) => {
    loadingToggle();
    ReportService.getReportStatus(id).subscribe({
      next: (response) => {
        setData(response);
        loadingToggle();
      },
    });
  };

  useEffect(() => {
    if (currentReportId) {
      getData(currentReportId);
    } else if (data) {
      setData(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportId]);

  const getType = (status: DisclosureStatusType): StatusIconType => {
    switch (status) {
      case 'COMPLETE':
        return 'complete';
      case 'INCOMPLETE':
        return 'work-in-progress';
      case 'REJECTED':
        return 'remind';
      case 'ACTIVE':
      default:
        return 'wait-for-action';
    }
  };

  return (
    <Card
      title={t('label:landing.disclosure_status', { year: currentYear ? getYearString(currentYear, i18n.language as Locale) : '' })}
      loading={!currentYear}
    >
      <CardLoading loading={loading}>
        {data && (
          <>
            <Timeline>
              <Timeline.Item
                dot={<StatusIcon data-testid="draft-complete-status" size="sm" type={getType(data.draftCompleteStatus)}></StatusIcon>}
              >
                {t('label:landing.save_complete')}
              </Timeline.Item>
              <Timeline.Item dot={<StatusIcon data-testid="on-process-status" size="sm" type={getType(data.onProcessStatus)}></StatusIcon>}>
                {t('label:landing.pending_approve')}
              </Timeline.Item>
              <Timeline.Item dot={<StatusIcon data-testid="approve-status" size="sm" type={getType(data.approveStatus)}></StatusIcon>}>
                {data.approveStatus === 'REJECTED' ? t('label:landing.rejected') : t('label:landing.approved')}
                {data.rejectedReason && (
                  <div className="my-2">
                    <p className="text-xs">{t('label:landing.rejected_reason')}:</p>
                    <p className="text-xs text-slate-500">{data.rejectedReason}</p>
                  </div>
                )}
                {data.updateDate && (
                  <p className="text-xs text-slate-500">
                    {t('label:landing.date')}: {`${dateToFormat(data.updateDate, dateTimeFormat)} `}
                  </p>
                )}
                {data.updateBy && (
                  <p className="text-xs text-slate-500">
                    {t('label:landing.approve_by')}: {data.updateBy}
                  </p>
                )}
              </Timeline.Item>
            </Timeline>
            <div className="flex justify-center pt-7">
              <LinkToLDP />
            </div>
          </>
        )}
      </CardLoading>
    </Card>
  );
};

export default DisclosureStatus;

import DisclaimerService from '@core/services/disclaimer.service';
import React, { useReducer } from 'react';

// type ActionType = 'SET';
type Action = { type: 'SET_DISCLAIMER'; payload: boolean };
type Dispatch = (action: Action) => void;
type State = {
  disclaimer: boolean;
};

const ProjectStateStateContext = React.createContext<State | undefined>(undefined);
const ProjectStateDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_DISCLAIMER': {
      return { ...state, disclaimer: action.payload };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

const ProjectStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { disclaimer: DisclaimerService.getDisclaimer() });

  return (
    <ProjectStateStateContext.Provider value={state}>
      <ProjectStateDispatchContext.Provider value={dispatch}>{children}</ProjectStateDispatchContext.Provider>
    </ProjectStateStateContext.Provider>
  );
};

const useProjectStateState = (): State => {
  const context = React.useContext(ProjectStateStateContext);
  if (context === undefined) {
    throw new Error('useProjectStateState must be used within a ProjectStateProvider');
  }
  return context;
};
const useProjectStateDispatch = (): Dispatch => {
  const context = React.useContext(ProjectStateDispatchContext);
  if (context === undefined) {
    throw new Error('useProjectStateDispatch must be used within a ProjectStateProvider');
  }
  return context;
};

export { ProjectStateProvider, useProjectStateState, useProjectStateDispatch };

import CardLoading from '@components/card-loading/CardLoading';
import { ReportService } from '@core/services/report.service';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import getYearString from '@helpers/utils/get-year-string';
import { Card, Progress } from '@mis/sushi-tailwind-react';
import { Locale } from '@model/local.types';
import { ReportProgress } from '@model/response/report-progress';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLandingState } from '../Landing.context';
import DisclosureProgress from './DisclosureProgress';

interface Data {
  progress: number;
  progresses: { label: string; progress: number }[];
}

const Disclosure = (): React.ReactElement => {
  const { i18n } = useTranslation(['translation']);
  const { currentYear, currentReportId } = useLandingState();
  const [data, setData] = useState<Data | undefined>();
  const [loading, loadingToggle] = useTrueFalse();

  const getData = (id: number) => {
    loadingToggle();
    ReportService.getReportProgress(id).subscribe({
      next: (response: ReportProgress) => {
        const { overAll, ...reportProgress } = response;
        const progresses = Object.entries(reportProgress).map(([key, value]) => ({ label: `reportNode.${key}.title`, progress: value }));
        setData({ progress: overAll || 0, progresses });
        loadingToggle();
      },
    });
  };

  useEffect(() => {
    if (currentReportId) {
      getData(currentReportId);
    } else if (data) {
      setData({ progress: 0, progresses: [] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportId]);

  return (
    <Card
      title={`ความคืบหน้าการยืนยันข้อมูลในระบบ ประจำปี ${currentYear ? getYearString(currentYear, i18n.language as Locale) : ''}`}
      loading={!currentYear}
    >
      <CardLoading loading={loading}>
        {data && (
          <>
            <div className="my-5">
              <Progress type="donut" round={false} percent={data.progress} />
            </div>
            <div className="flex flex-col gap-2">
              {data.progresses.map((p, i) => (
                <DisclosureProgress key={i} progress={p.progress} label={p.label} />
              ))}
              <div>
                <div className="font-bold">หมายเหตุ:</div>
                <div>เปอร์เซ็นต์ (%) ที่แสดงมิได้หมายถึงความครบถ้วนการเปิดเผยข้อมูล</div>
              </div>
            </div>
          </>
        )}
      </CardLoading>
    </Card>
  );
};

export default Disclosure;

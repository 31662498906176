import useServerCurrentDateTime from '@helpers/hooks/useServerCurrentTime';
import { Spin } from '@mis/sushi-tailwind-react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import CompanyInformation from './companyInformation/CompanyInformation';
import Disclosure from './disclosure';
import ESGInformation from './ESGInformation';
import { LandingProvider } from './Landing.context';
import OperationInformation from './operationInformation';
import DisclosureStatus from './disclosureStatus/DisclosureStatus';
import { useNavigate } from 'react-location';
import DiscountConditionSummary from './discountConditionSummary';

const LandingPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const [{ currentDateTime, defaultYear }, isLoading] = useServerCurrentDateTime();
  const [year, setYear] = useState<number>();
  useEffect(() => {
    if (currentDateTime && defaultYear) {
      const query = new URLSearchParams(window.location.search);
      const queryYear = query.get('year');
      const minYear = 2022;
      const maxYear = defaultYear ? defaultYear : currentDateTime ? dayjs(currentDateTime).get('year') : 2022;
      const year = defaultYear ? defaultYear : currentDateTime ? dayjs(currentDateTime).get('year') : 2022;
      if (query.has('year')) {
        query.delete('year');
      }
      const to = query.has('year') ? '/?' + query.toString() : '/';
      if (queryYear && minYear <= Number(queryYear) && maxYear >= Number(queryYear)) {
        navigate({ to, replace: true });
        setYear(Number(queryYear));
        return;
      }
      setYear(year);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDateTime, defaultYear]);

  if (isLoading || !year) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <LandingProvider currentYear={year} currentDateTime={currentDateTime} defaultYear={defaultYear}>
      <div className="flex flex-col gap-4">
        <ESGInformation />
        <CompanyInformation />
      </div>
      <div className="grid gap-6.5 sm:grid-cols-1 md:grid-cols-3">
        <div className="order-last md:order-first md:col-span-2">
          <OperationInformation />
        </div>
        <div className="flex flex-col gap-4">
          <Disclosure />
          <DiscountConditionSummary />
          <DisclosureStatus />
        </div>
      </div>
    </LandingProvider>
  );
};

export default LandingPage;

import { useProfileState } from '@context/Profile.context';
import { useReportDispatch, useReportState } from '@context/Report.context';
import { useReportStatusDispatch } from '@context/ReportStatus.context';
import { ReportService } from '@core/services/report.service';
import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { useMatch } from 'react-location';

const InitialReportData: React.FC = ({ children }) => {
  const {
    params: { reportId },
  } = useMatch();
  const { profile } = useProfileState();
  const reportStatusDispatch = useReportStatusDispatch();
  const { currentNode, currentUserReports } = useReportState();
  const reportDispatch = useReportDispatch();

  useEffect(() => {
    const role = profile?.roles.find((r) => r.roleName.includes('ESG'));
    if (role) {
      reportStatusDispatch({
        type: 'SET_USER_ROLE',
        payload: role?.roleName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.roles]);

  useEffect(() => {
    reportStatusDispatch({ type: 'SET_PROFILE', payload: profile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    reportStatusDispatch({ type: 'SET_CURRENT_NODE', payload: currentNode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNode]);

  useEffect(() => {
    reportStatusDispatch({ type: 'SET_CURRENT_USER_REPORTS', payload: currentUserReports });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserReports]);

  useEffect(() => {
    ReportService.getReport(reportId).subscribe(
      (x) => {
        reportStatusDispatch({
          type: 'SET_AS_OF_YEAR',
          payload: x.asOfYear,
        });
        reportStatusDispatch({
          type: 'SET_REPORT_STATUS',
          payload: x.status,
        });
        reportDispatch({ type: 'LOAD_REPORT_DETAIL' });
      },
      (e: AxiosResponse) => {
        if (e.status === 404) {
          window.location.href = '/';
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  return <>{children}</>;
};

export default InitialReportData;

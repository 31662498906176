import { UserRole } from '@model/enum/user-role.enum';
import { Profile } from '@model/profile';
import React, { useReducer } from 'react';

// type ActionType = 'SET';
type Action = { type: 'SET'; payload: Profile | null };
type Dispatch = (action: Action) => void;
type State = {
  profile: Profile | null;
  isApprover: boolean | null;
};

const ProfileStateContext = React.createContext<State | undefined>(undefined);
const ProfileDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET': {
      const isApprover = action.payload && action.payload.roles.map((role) => role.roleName).includes(UserRole.ESG_APPROVER);
      return { ...state, profile: action.payload, isApprover };
    }
    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

interface ProfileProviderProps {
  profile: Profile | null;
  // isApprover: boolean | null;
}
const ProfileProvider: React.FC<ProfileProviderProps> = ({ profile, children }) => {
  const [state, dispatch] = useReducer(reducer, { profile, isApprover: null });

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>{children}</ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  );
};

const useProfileState = (): State => {
  const context = React.useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error('useProfileState must be used within a ProfileProvider');
  }
  return context;
};
const useProfileDispatch = (): Dispatch => {
  const context = React.useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error('useProfileDispatch must be used within a ProfileProvider');
  }
  return context;
};

export { ProfileProvider, useProfileState, useProfileDispatch };

import { Icon } from '@mis/sushi-tailwind-react';
import React from 'react';

interface SiderLayoutProps {
  label: string;
  hasCurrentUser?: boolean;
}

function SiderLayout({ label, hasCurrentUser }: SiderLayoutProps): React.ReactElement {
  return (
    <span className="flex flex-row gap-1">
      {label}
      {hasCurrentUser && <Icon name="account_circle" color="text-orange-500" size="text-xl" type="outlined" />}
    </span>
  );
}

export default React.memo(SiderLayout);

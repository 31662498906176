import { UseWebsocketRet } from '@helpers/hooks/useWebsocket';
import { StompSubscription } from '@stomp/stompjs';
import React, { useCallback, useEffect, useRef } from 'react';

interface ReportOutletProps extends Pick<UseWebsocketRet, 'subscribe' | 'unsubscribe' | 'getHeaders'> {
  children?: React.ReactNode;
  connected: boolean;
  currentNode: string;
  reportId: number | string;
}

export default function ReportOutlet({
  children,
  connected,
  currentNode,
  subscribe,
  unsubscribe,
  getHeaders,
  reportId,
}: ReportOutletProps): React.ReactElement | null {
  const subRef = useRef<StompSubscription>();

  const handlerSubscribe = useCallback((reportId: number | string, currentNode: string) => {
    try {
      subRef.current = subscribe(`/current-user/${currentNode}/${reportId}`, (message) => {
        console.log('message: ', message);
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerUnsubscribe = useCallback(() => {
    if (subRef.current) {
      try {
        unsubscribe(subRef.current.id);
      } catch (error) {
        console.error(error);
        const headers = getHeaders();
        subRef.current.unsubscribe(headers);
      }
      subRef.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connected && reportId && currentNode) {
      handlerSubscribe(reportId, currentNode);
    }
    window.onbeforeunload = () => {
      // e.preventDefault();
      handlerUnsubscribe();
      // return '';
    };
    return () => {
      handlerUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, currentNode, reportId]);

  if (!connected) {
    return null;
  }

  return <>{children}</>;
}

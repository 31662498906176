import { Button, Card } from '@mis/sushi-tailwind-react';
import React, { useEffect, useMemo, useState } from 'react';
import TableRow from './TableRow';
import Export from './Export';
import { reportNodes } from '@helpers/utils/report-node';
import { useLandingDispatch, useLandingState } from '../Landing.context';
import { ReportService } from '@core/services/report.service';
import { ReportInfo } from '@model/report-info';
import { finalize, forkJoin, of, switchMap } from 'rxjs';
import CardLoading from '@components/card-loading/CardLoading';
import useTrueFalse from '@helpers/hooks/useTrueFalse';
import { useNavigate } from 'react-location';
import { useTranslation } from 'react-i18next';
import { useProfileState } from '@context/Profile.context';
import { ReportStatusResponse } from '@model/response/report-status.response';
import { useYearOptions } from '@helpers/hooks/useYearOptions';
import useServerCurrentDateTime from '@helpers/hooks/useServerCurrentTime';

const OperationInformation = (): React.ReactElement => {
  const landingState = useLandingState();
  const dispatch = useLandingDispatch();
  const { t } = useTranslation(['button', 'label', 'translation']);
  const [reportInfo, setReportInfo] = useState<null | ReportInfo>(null);
  const [reportStatus, setReportStatus] = useState<null | ReportStatusResponse>(null);
  const { profile } = useProfileState();
  const [loading, loadingToggle] = useTrueFalse();
  const navigate = useNavigate();
  const [cDateTime] = useServerCurrentDateTime();
  const years = useYearOptions(cDateTime.firstYear, landingState.currentDateTime, landingState.defaultYear);
  const excludeApproveNodes = useMemo<string[]>(() => {
    if (profile && Array.isArray(profile.roles)) {
      const isCreator = profile.roles.some((r) => r.roleName === 'ESG_CREATOR');
      if (isCreator) {
        return ['approveReport'];
      }
      const isApprover = profile.roles.some((r) => r.roleName === 'ESG_APPROVER');
      if (isApprover) {
        return ['sendToApprover'];
      }
    }
    return [];
  }, [profile]);
  const isApprover = useMemo(() => {
    return profile ? profile.roles.some((r) => r.roleName === 'ESG_APPROVER') : false;
  }, [profile]);
  const isApprove = useMemo(() => {
    if (
      reportStatus &&
      isApprover &&
      ['COMPLETE'].includes(reportStatus.draftCompleteStatus) &&
      ['INCOMPLETE', 'COMPLETE'].includes(reportStatus.onProcessStatus)
    ) {
      return true;
    }
    return false;
  }, [isApprover, reportStatus]);
  const disableStartButton = useMemo(() => {
    if (isApprover && isApprove === false) {
      return true;
    }
    return false;
  }, [isApprove, isApprover]);
  const currentYear = useMemo(() => {
    if (!years.some((year) => year === landingState.currentYear)) {
      return years[0];
    }
    return landingState.currentYear;
  }, [landingState.currentYear, years]);

  useEffect(() => {
    if (currentYear) {
      loadingToggle();
      ReportService.getReportInfo(currentYear)
        .pipe(
          switchMap((reportInfo) => {
            return forkJoin({ reportInfo: of(reportInfo), reportStatus: ReportService.getReportStatus(reportInfo.id) });
          }),
          finalize(() => {
            loadingToggle();
          })
        )
        .subscribe({
          next: ({ reportInfo, reportStatus }) => {
            setReportInfo(reportInfo);
            setReportStatus(reportStatus);
            dispatch({ type: 'SET_CURRENT_REPORT_ID', payload: reportInfo.id });
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const goToScope = () => {
    if (isApprover) {
      if (isApprove) {
        navigate({ to: `/report/${reportInfo?.id}/approveReport`, replace: true });
      }
      return;
    } else {
      navigate({ to: `/report/${reportInfo?.id}/reportScope`, replace: true });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Card
        title={
          <div className="!flex justify-between items-center w-full">
            <div className="text-center">{t('label:landing.operation_information')}</div>
            <div>
              <Button data-testid="start-filling-esg" color="primary" onClick={goToScope} disabled={disableStartButton}>
                {isApprover ? t('button:start_approve_report') : t('button:start_filling_esg')}
              </Button>
            </div>
          </div>
        }
      >
        <CardLoading loading={loading}>
          {reportInfo && reportStatus && (
            <table className="w-full border-collapse table-auto">
              <thead>
                <tr>
                  <th className="w-2/5 font-normal text-left text-[#9DA6AD]">หัวข้อ</th>
                  <th className="w-1/5 font-normal text-left text-[#9DA6AD]"></th>
                  <th className="w-1/5 font-normal text-left text-[#9DA6AD]">ปรับปรุงล่าสุด</th>
                  <th className="w-1/5 font-normal text-left text-[#9DA6AD]">โดย</th>
                </tr>
              </thead>
              <tbody>
                {reportNodes()
                  .filter((x) => !excludeApproveNodes.includes(x.nodeName))
                  .map(({ nodeName, subNodes }) => {
                    return (
                      <TableRow
                        key={nodeName}
                        subNodes={subNodes}
                        reportInfo={reportInfo}
                        nodeName={nodeName}
                        reportStatus={reportStatus}
                      />
                    );
                  })}
              </tbody>
            </table>
          )}
        </CardLoading>
      </Card>
      <Export />
    </div>
  );
};

export default OperationInformation;

import dayjs from 'dayjs';
import { useMemo } from 'react';

export function useYearOptions(firstYear: number, date?: Date | null, defaultYear?: number | null): number[] {
  // console.log(env);
  return useMemo(() => {
    if (date) {
      const years = [];
      const currentYear = defaultYear ? defaultYear : dayjs(date).get('year');

      // code for dev and test only
      // if (currentYear < 2026 && ['development', 'dev', 'test'].includes(env.nodeEnv)) {
      // if (currentYear < 2026 && ['development', 'dev'].includes(env.nodeEnv)) {
      //   currentYear = 2026;
      // }

      // if (currentYear >= startYear) {
      //   currentYear++;
      // }
      for (let year = firstYear; year <= currentYear; year++) {
        if (year >= firstYear) {
          years.push(year);
        }
      }
      return years;
    }
    return [];
  }, [date, defaultYear, firstYear]);
}

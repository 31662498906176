import { Locale } from '@model/local.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type UpdateFactory<T extends string> = {
  [P in Locale as `${T}${Capitalize<P>}`]: T;
};

export default function useFieldTranslate<R extends string>(field: R): keyof UpdateFactory<R> {
  const { i18n } = useTranslation();
  return useMemo(() => {
    const l = i18n.language[0].toUpperCase() + i18n.language.slice(1);
    return `${field}${l}` as unknown as keyof UpdateFactory<typeof field>;
  }, [field, i18n.language]);
}
